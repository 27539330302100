@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "layout";
@import "atoms";
@import "molecules";
@import "organismes";
@import "templates";
@import "comingSoonCard";
@import "popup";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

br {
  display: block;
  content: "";
  padding: 2px 0;
}

a {
  cursor: pointer;
}

// - - - - - - - Fonts - - - - - - - - - - - - - 

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* Tajawal Font */
@font-face {
  font-family: "Tajawal";
  src: local("Tajawal-Regular"), url("../assets/fonts/Tajawal/Tajawal-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-M";
  src: local("Tajawal-Medium"), url("../assets/fonts/Tajawal/Tajawal-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Tajawal-B";
  src: local("Tajawal-Bold"), url("../assets/fonts/Tajawal/Tajawal-Bold.ttf") format("truetype");
}

/* Mont Font */
@font-face {
  font-family: "Mont";
  src: local("Mont-Regular"), url("../assets/fonts/Mont/Mont-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-M";
  src: local("Mont-Medium"), url("../assets/fonts/Mont/Mont-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Mont-B";
  src: local("Mont-Bold"), url("../assets/fonts/Mont/Mont-Bold.ttf") format("truetype");
}

// - - - - - - - - - - - - - TOP Grid - - - - - - - - - - - - - - 

.homepage{
  background-color: $Grey-02;
  height: 100%;
  border: 8px solid #FF6A56;
  position: fixed;
  overflow: auto;
  width: 100%;
}
.app-container {
  scroll-padding-top: 10px;
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center; 
  gap: 8px;
  padding: 16px;
}

.scroll_anime_box {
  position: fixed;
  width: 28px;
  height: 40px;
  bottom: 16px;
  background-color: $Orange;
  border: 2px solid $Grey-02;
  color: #fff;
  opacity: 0;
  z-index: 99;
  border-radius: 16px;
  overflow: hidden;
  margin-left: -18px;
  left: 50%;
    
  svg{
    color: $White;
  }
}

.scroll_anime_box.visible {
  opacity: 1;
}

.grid-item {
  width: 100%;
  height: 100%;
  min-height: 230px;
  background-color: $White;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  padding: 24px 0;
  border-radius: 16px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  color: $Orange;
  font-size: 50px;
  font-weight: 700;
}

h7{
  font-family: Outfit;
  line-height: 80% ;
  font-weight: 600;
  font-size: 29px;
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 


.logo_card {
  gap: 8px;
  color: $Dark;
  background-color: $Orange;
  cursor: pointer;

  .logo_top_box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  
    h3 svg{
      width: 92px !important;
      height: auto;
      transition: all 0.13s ease;
      }
    h4{
      width: 100%;
    }
  }

  &:hover {
    h3 svg{
      width: 110px !important;
      height: auto;
      
    }
  }
  
}

.slogan_card{
  color: $Grey-04;
  gap: 40px !important;
  .slogan_box{
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-family: Outfit;
    font-size: 40px;
    font-weight: 600;
    line-height: 70% ;
    h3{
      color: $Orange;
    }
  }
}

// - - - - - - - - - - - - 

.service_card {
  gap: 16px;
  color: $White;

  svg{
    min-width: 88px;
    height: 88px;
    transition: all 0.2s ease;
  }
  &:hover {
    background-color: $Orange;
    cursor: pointer;

    svg{
      min-width: 120px;
      height: 120px;

    }
  }


  .service_text_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: Outfit;
    font-size: 36px;
    font-weight: 600;
    line-height: 70% ;

    h3{
      font-size: 34px;
      color: #ffffff60;
    }

  }
}
.tool_color{
  background-color: $Blue;
}
.resos_color{
  background-color: $Purple;
}
.ideaz_color{
  background-color: $Green;
}

.apps_color{
  background-color: $Greenblue;
}

// - - - - - - - - - - - - 

.soon_service_card {
  color: $Grey-03;
  gap: 8px;

  .icon {
    min-width: 88px;
    height: 88px;
  }
  .soon_service_text_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: Outfit;
    font-size: 34px;
    font-weight: 600;
    line-height: 70% ;
    h3{
      font-size: 36px;
      color: $Grey-01;
    }
  }
}

// - - - - - - - - - - - - 

.others_card {
  gap: 10px;
  color: $Orange;

  .icon{
    width: 70px ; 
    height: auto;
    stroke-width: 1.2px !important;
    transition: all 0.2s ease;
  }

  &:hover {
    background-color: $Orange;
    color: $White;
    cursor: pointer;
    .icon{
    width: 94px;
    }

  }

  .others_text_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: Outfit;
    font-size: 26px;
    font-weight: 700;
    line-height: 80% ;
    text-align: center;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 27px;
    }
  }
}

// - - - - - - - - - - - - 

.access_card {

  .access_btn_box{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: Outfit;
    font-size: 18px;
    font-weight: 500;
    line-height: 80% ;
    text-align: center;
    width: 100%;

  .access_btn,
  .lined_btn {
    width: 60%;
    height: 46px;
    min-width: 150px;
    color: $White;
    background-color: $Orange;
    cursor: pointer;
    border: 2px solid $Orange;  


    &:hover{
    background-color: $Dark;
    color: $White;
    border: 2px solid $Dark;  
    }

    &[data-lang=jp] {
      font-family: Outfit;
      font-size: 17px;
      font-weight: 500;
      line-height: 90%;

    }

    svg {
      width: 26px;
      height: 26px;
      stroke-width: 1.7px;
    }
  }

  .lined_btn {
    border: 2px solid $Orange;
    color: $Orange;
    background-color: $White;
  }
}
}

.text_link_box{
    color: $Orange;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    font-family: Outfit;
    font-weight: 400;
    line-height: 80%;
    text-align: center;
    width: 100%;

    .icon-set {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-items: center;
      text-align: center;
      gap: 0px !important;
  
      h5{
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 36px;
          stroke-width: 1.65px;
          transition: all 0.2s ease;
    
          &:hover {
            color: $Dark;
            cursor: pointer;
            width: 48px;
  
          }
        }
      }
    }
  .link{
    font-size: 17px;
    cursor: pointer;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 16px; 
      font-weight: 500;
      line-height: 100%;
    }
  
    
    &:hover{
      color: $Dark;
    }
  }

  div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    line-height: 90%;
  }
  h4{
    font-size: 15px;
    color: $Orange;
    line-height: 100%;
  }
  h3{
    font-size: 15px;
    color: $Grey-04;
    line-height: 90%;
  }
  h5{
    display: none;
    
  }

  .langs_btn_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-family: Outfit;
    font-size: 15.5px;
    font-weight: 400;
    line-height: 80%;
    text-align: center;
  
    .langs_btn {
      
      width: 100px;
      color: $Orange;
      text-align: center;
      padding: 0;
      height: 40px;
      border: 2px solid $Orange;
      background-color: $White;
  
      &:hover {
        background-color: $Orange;
        color: $White !important;
        border: 2px solid $Orange;
      }
    }
  
    .selected {
      border: 2px solid $Grey-03;
      color: $White;
      background-color: $Grey-03;
      cursor: pointer;
      &:hover{
        border: 2px solid $Grey-03;
        color: $White;
        background-color: $Grey-03;
        cursor: default;
  

      }
    }
  
    .jp {
      font-size: 15px;
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
    }
  }

}

// - - - - - - - - - - - - 

.banner_box{
  background-size: cover; 
  background-position: center center; 
  background-color: $Orange;
  overflow: hidden;
  position: relative; 
  

.arrow{
  width: 80px;
  height: 80px;
  border-radius: 32px;
  position: absolute;
  bottom: -14px;
  right: -14px;
  transition: all 0.1s ease;

}
.banner_bg{
  width: 78%;
  position: absolute;
  top: -64px;
  left: -64px;
  transition: all 0.1s ease;

}
.slogan_illus{
  width: 184px;
  height: auto;
  z-index: 2;
  transition: all 0.1s ease;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

  &:hover {
    cursor: pointer;
    .arrow{
      width: 96px;
      height: 94px;
      bottom: -18px;
      right: -18px;
    }
    .banner_bg{
      width: 110%;
      top: -88px;
      left: -88px;
      animation: rotate360 4s infinite linear;
    }
    .slogan_illus{
      width: 226px;
    }

  }
}
// ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^ ^  ^ ^ ^ ^ ^ ^ ^ 

@media (max-width: 20000px) and (min-width: 1401px){
  .app-container {
    grid-template-columns: repeat(5, 1fr);
  }
  .empty_last{
    display: none;
  }

}

@media (max-width: 1400px) and (min-width: 1141px) {
  .app-container {
    grid-template-columns: repeat(4, 1fr);
  }
  .empty_last{
    display: none;
  }
}

@media (max-width: 1140px) and (min-width: 841px) {
  .app-container {
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
  }

}

@media (max-width: 840px) and (min-width: 640px) {
  .app-container {
    height: 100%;
    grid-template-columns: repeat(2, 1fr);
     }
     .empty_last{
      display: none;
    }
}

@media (max-width: 640px) {
  .app-container {
    height: 100%;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .grid-item {
    min-width: 260px;
    max-width: 360px;
    min-height: 240px !important;
    padding: 0;
  }
  .empty_card{
    display: none;
  }
  .empty_last{
    display: none;
  }
}
  
// /* Hide the 21st grid item by default */
// .grid-item:nth-child(21) {
//   display: none;
// }

// /* Show the 21st grid item within the specified breakpoint range */
// @media (max-width: 1140px) and (min-width: 841px) {
//   .grid-item:nth-child(21) {
//     display: flex;
//   }
// }
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

@media screen and (max-width: 840px) {

  .text_link_box {
    .langs_btn_box {
      gap: 6px;

      .langs_btn {
        height: 46px;
        width: 120px;
        font-size: 17px;
      }
    }

    .icon-set {
      gap: 6px;
  
      h5{
        width: 54px;
        height: 40px;
      }
      svg {
        width: 40px;
  
        &:hover {
          width: 58px;

        }
      }
    }

  }

  .text_link_box {
   div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    line-height: 90%;
   }
   .link {
      font-size: 19px;
      cursor: pointer;
  }
  }

}

// - - - - - - - - - -  - - - - - - - TOP Grid END - - - - - - - - - - - - -

.soonbanner_box {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-items: center;
  justify-content: center;
  gap: 4px;
  min-width: 274px;
  max-width: 274px;
  height: 130px;
  background-color: $Orange;
  border-radius: 16px;
  color: $White;
  font-family: Outfit;
  font-size: 17px;
  line-height: 100%;
  font-weight: 300;
  padding: 20px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    gap: 6px;
    font-size: 16px;
    font-weight: 400;
    line-height: 110%;
  }


  span {
    z-index: 3;
    transition: all 0.3s ease;
  }

  h3 {
    width: 150px;
    z-index: 3;
    transition: all 0.3s ease;
  }

  h4 {
    width: 160px;
    position: absolute;
    right: -20px;
    transition: all 0.1s ease;
  }

  &:hover{
    max-height: 130px;
    gap: 0px;

    h3 {
      width: 240px;
    }
    span{
      display: none;
    }
    h4 {
      animation: rotate360 6s infinite linear;
    }

  }

}
.popup-secondary-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 330px;
  height: auto;
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000000;
}

#profile {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  // padding: 0 12px;
  position: relative;

  .user-tab {
    display: flex;
    flex-direction: column;
    // gap: 10px;

    .user-tab-header {
      background-color: $Grey-00;
      position: sticky;
      top: 0;
      z-index: 1;
      display: flex;
      padding: 16px 8px 4px 8px;
      align-items: center;
      gap: 4px;
      flex-shrink: 0;
      color: var(--primary-color);

      svg {
        width: 44px;
        height: 44px;
      }

      .user-tab-title {
        color: $Text_color;
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 28.8px */
      }

      .user-tab-number {
        color: var(--primary-color);
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        /* 28.8px */
      }
    }
  }
}

.\!text-sm {
  font-size: 0.875rem !important;
  line-height: 1rem !important;
}

.pr-3 {
  padding-right: 0rem;
  width: 34px;
  height: 34px;
}

.text-color {
  color: $Text_color;
}

.hover-grey {

  &:hover {
    background-color: $Grey-00;
  }
}

.text-orange {
  color: $Orange !important;
}

.bg-grey-default {
  background-color: #EEEFF4;
}

.text-grey-sec {
  color: #ABACCA;
}

.text-grey-tri {
  color: #878BB7;
}


.page-title-box {
  display: flex;
  gap: 10px;
  align-items: center;
  color: var(--primary-color);

  h2 {
    font-family: Outfit;
    font-size: 44px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  svg {
    width: 68px;
    height: auto;
    stroke-width: 1.6px;
  }
}

.slogan-box {
  padding: 100px 0px;
  display: flex;
  justify-content: center;

  h3 {
    color: var(--def-orange, #FF6956);
    text-align: center;
    font-family: outfit;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    max-width: 530px;
    /* 61.2px */
    letter-spacing: -0.6px;
  }

  .sp-slogan {
    color: var(--def-dark, $Text_color);
  }
}

.sp-parag-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 48px;
  flex-direction: column-reverse;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
  }

  span{
    color:$Orange;
    font-weight: 500;
  }

  picture {
    width: auto;
    display: flex;
    justify-content: center;
    // padding: 10px;

    .newsletter-image {
      width: 100%;
      height: auto;
      object-fit: cover;
      border-radius: 24px;
    }
  }


}

.services-box{
  display: flex;
  flex-direction: row-reverse;
  gap: 32px;
  align-items: center;
  justify-self:center;
  margin: 32px 0;

  img{
    width: 90%;
    min-width: 360px;
  }
}

.switch-staticpagearticle {
  flex-direction: row-reverse;
}

.swiper {
  color: aqua;
  background-color: #878BB7 !important;
}

.paragraph-image {
  // min-width: 240px;
  // height: 316px;
  object-fit: cover;
  // border-radius: 24px;
}

.paragraph-text-cont {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
  min-width: 56%;
}

.paragraph, .paragraph_static_page {
  color: var(--def-darker, #39274E);
  font-family: Outfit;
  font-size: 19px;
  font-style: normal;
  font-weight: 300;
  line-height: 132%;
  letter-spacing: .4px;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    letter-spacing: 1.3px;
  }
}

.paragraph-title {
  color: var(--def-orange, #FF6956);
  font-family: outfit;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 600;
    font-size: 28px;
    line-height: 155%;
  }

}

.big-image-box {
  display: flex;
  align-content: center;
  justify-content: center;
  min-width: 100%;
  // padding: 24px;

  .big-image {
    width: 100%;
    object-fit: cover;
    padding: 64px 0;
  }
}

.Parag-imag {
  height: 200px;
  border-radius: 24px;
  width: 100%;
  object-fit: cover;
}


.orderlist-cont {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.orderlist-item-number {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--primary-color);
  line-height: 100%;
  font-weight: 800;
  border: 2.4px solid var(--primary-color);
  min-width: 26px;
  min-height: 26px;
}

.orderlist-item-number-filled {
  width: 26px;
  // height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 14px;
  background-color: var(--primary-color);
  font-weight: 600;
}

.orderlist-item-cont {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.orderlist-item-text {
  color: var(--def-orange, #FF6956);
  font-family: Outfit;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.orderlist-item-desc {
  margin-left: 36px;
  color: var(--def-darker, #39274E);
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.aboutus-text {
  color: var(--def-darker, #39274E);
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.aboutus-button {
  width: 272px;
  font-family: outfit;
}

.default-cont {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: baseline;
}

.aboutus-gray-title {
  color: var(--gray-grey-04, #CCCEDE);

  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 38.4px */
}

.grey-color-aboutus {

  color: var(--gray-grey-04, #CCCEDE) !important;
}

.bg-grey-color-aboutus {
  background-color: var(--gray-grey-04, #CCCEDE);
}

.aboutus-below-orderlist {
  color: var(--gray-grey-04, #CCCEDE);
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.orderlist-item-text2 {
  color: var(--def-orange, #FF6956);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.aboutus-title-icon2 {
  color: #FF6956;
}

.contact-cont-box {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 700px;
}

.contact-page-box {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 40px 64px 125px;
  max-width: 780px;

  p {
    padding: 0 16px;
  }
}

.contact-form-box {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.contact-radio-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  .radiobox {
    gap: 6px;
    width: auto;
    padding: 0;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}

.contact-input-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  .phone-input {
    width: 400px !important;
  }
}

.contact-btn-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 8px;

  .checkbox {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;

    // width: 200px;
    .checkbox-right-icon {
      color: var(--primary-color);
      flex-shrink: 0;
      width: 32px;
      height: 32px;
      stroke-width: 1.6px;
    }
  }

}

.contact-btn {
  width: 200px;
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  height: 54px !important;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 400;
  }
}

.contact-checkbox-icon {
  color: $Blue;
  width: 30px;
}

.checked-green {
  background-color: $Blue !important;
  border: 1.6px solid $Blue !important;
}

.static-page-hero {
  background-color: $White;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100%;
}

.static-page-privacy-hero {
  background-color: $White;
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 100%;

  .static-page {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 40px 64px 125px;
    width: 1000px;

    .static-page-innerbox {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 80%;
    }
  }
  
  .static-page {
    display: flex;
    flex-direction: column;
    gap: 96px;
    padding: 40px 64px 125px;
    width: 1160px;
  }
  

  span {
    font-size: 20px;
    height: 48px;
    line-height: 150%;
    color: $Orange;
  }

  p {
    font-size: 17px;
    line-height: 140%;
  }
}


.sp-inner-content-box {
  display: flex;
  flex-direction: row;
  align-items: left;
  justify-content: center;
  gap: 48px;
  background-color: $Grey-00;
  padding: 20px 48px 20px 20px;
  border-radius: 32px;

}

.sp-inner-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  gap: 20px;
  width: 100%;

  .newsletter-btns-box {
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;
    gap: 8px;

    .newsletter-btn {
      width: 140px;
    }

    .newsletter-btn-success {
      width: 140px;
      background-color: #00E165 !important;

    }
  }
}


.contact-success-card {
  color: var(--def-darker, #39274E);
  font-family: Outfit;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 124%;
  padding-bottom: 20px;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 400;
    line-height: 130%;
  }

}

/* CSS for the tooltip */
.tooltip {
  position: relative;
  display: inline-block;
  z-index: 2;
}

.access {
  position: relative;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 54px;
  border-radius: 20px;
  background-color: var(--primary-color);
  color: $White;
  line-height: 100%;
  height: 32px;
  width: 72px;
  padding-bottom: 2px;

  &:hover{
    background-color: $Text_color;
    color: $White;
    border:  2px solid $Text_color;
  }

  .extra-icon{
  .extra-title{
  display: flex !important;
  font-size: 14px !important;
  padding-top: 1px;
  }
}
}

.lined {
  color: var(--primary-color);
  background-color: $White;
  border:  2px solid var(--primary-color);

}

.tooltiptext {
  visibility: hidden;
  font-family: Outfit;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  white-space: nowrap;
  background-color: $Orange;
  color: #fff;
  text-align: left;
  border-radius: 8px;
  padding: 10px;
  position: absolute;
  top: 50%;
  right: calc(100% + 12px);
  transform: translate(0, -50%);

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    line-height: 100%;
    font-weight: 500;
  }

  /* Add tooltip arrow */
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    /* Half of the arrow height */
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent $Orange ;
  }
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

//-----------------Error Page Styles---------------------

.uxersoon-error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: $Orange;

  
  
  .error-page-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 720px;
  margin: auto;

  .logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 7px;
    width: 100%;
    padding: 0px 0 32px;
    font-family: Outfit;
    font-size: 48px;
    font-weight: 600;
    line-height: 100%;
    text-align: center;
    color: $Dark;

    h3{
      font-family: Outfit;
      font-size: 50px;
      font-weight: 700;
      line-height: 100%;
      text-align: center;
      color:#39274e3b;
    }
    h4{
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 37px;
      font-weight: 900;
      line-height: 100%;
      text-align: center;
      color:#39274e3b;
      letter-spacing: -1px;
    }
  }
  .error-page_btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 260px;
    min-height: 54px;
    border-radius: 34px;
    padding: 0 12px 0 20px;
    border: 2px solid $Dark;
    font-size: 18px;
    margin: 24px 0;
    color: $Dark;

    .button-right-icon {
      height: 32px;
      width: 32px;
    }

    &:hover {
      background-color: $Dark;
      border: 2px solid $Dark;
      color: $Orange;

    }
  }

}

.error-page-logo_hor {
  width: 380px;
  height: auto;
  color: $Dark;
  display: flex;
}
.error-page-logo_ver {
  width: 380px;
  height: auto;
  color: $Dark;
  display: none;
}

}

//-----------------SOON Page Styles---------------------

.soon-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: $Orange;
  .soon-page-box{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 720px;
  margin: auto;

  .logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    padding: 0px 0 120px;
    font-family: Outfit;
    font-size: 48px;
    font-weight: 600;
    line-height: 100%;
    text-align: center;
    color: $Dark;

    h3{
      font-family: Outfit;
      font-size: 88px;
      font-weight: 800;
      line-height: 80%;
      text-align: center;
      color:$Dark;
    }
    h4{
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 50px;
      font-weight: 900;
      line-height: 100%;
      text-align: center;
      color:$Dark;
      letter-spacing: -2px;
    }
  }
}

.soon-page-logo {
  width: 80%;
  max-width: 260px;
  height: auto;
  color: $Dark;
  display: flex;
  // padding-bottom: 10px;
}
}

//----------------- UXER COMING SOON --------------

.uxersoon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 140px;
  width: 100%;
  max-width: 720px;
  margin: auto;

  .logo-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: 100%;
    padding: 64px 0 32px;
    font-family: Outfit;
    font-size: 48px;
    font-weight: 600;
    line-height: 100%;
    text-align: center;
    color: $Yellow;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      line-height: 100%;
      font-weight: 600;
    }
  }

  .uxersoon-logo {
    width: 160px;
    height: auto;
    color: $Yellow;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    width: 100%;
  }

  .list {
    background-color: #00E165;
  }

  h2,
  h3 {
    font-size: 32px;
    font-family: Outfit;
    font-weight: 600;
    line-height: 90%;
    text-align: center;
    color: $Yellow;
  }
  
  h3 {
    color: $Grey-04;
  }

  p {
    font-size: 18px;
    font-family: Outfit;
    font-weight: 400;
    line-height: 120%;
    text-align: center;
    color: $Dark;
    width: 90%;
  }

  .uxersoon_btn,
  .lined_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    min-height: 68px;
    border-radius: 34px;
    padding: 0 20px 0 32px;
    background-color: $Yellow;
    font-size: 22px;
    margin: 32px 0;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 17px; 
      font-weight: 500;
      line-height: 90%;
    }

    span {
      width: fit-content;
    }

    .button-right-icon {
      height: 36px;
      width: 36px;
    }

    &:hover {
      background-color: $Dark;
    }
  }

  .lined_btn {
    background-color: $White;
    border: 2px solid $Yellow;
    color: $Yellow;

    &:hover {
      background-color: $Yellow;
      color: $White;
    }
  }

  // -----------------

  .list-box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 75%;

    .list-title-box {
      display: flex;
      flex-direction: row;
      gap: 8px;
      align-items: center;
      justify-content: center;

      .number-list {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-family: Outfit;
        line-height: 100%;
        font-weight: 500;
        border-radius: 50%;
        color: $Yellow;
        line-height: 100%;
        border: 2.4px solid $Yellow;
        min-width: 26px;
        min-height: 26px;
      }

      h2 {
        color: $Yellow;
        font-family: Outfit;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
      }

    }

    .list-text {
      color: $Dark;
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      text-align: center;

    }

  }
}

.big-img {
  width: auto;
  height: 440px;
  background-color: #00E165;
  padding: 0;
}

// ---------------------------- Privacy / Terms -----------------------------

.tab-hero {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 22px;
  font-family: Outfit;
  line-height: 100%;
  font-weight: 500;
  text-wrap: wrap;
  color: $Grey-02;
  gap: 12px;


  .tab-title-box {
    display: flex;
    align-items: center;
    color: $Grey_05;
    cursor: pointer;
    border-radius: 12px;

    &:hover {
      color: $Grey-07;
    }

    h2 {
      font-family: Outfit;
      font-size: px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      text-wrap: nowrap;
    }

    svg {
      max-block-size: 64px;
      min-block-size: 64px;
      height: auto;
    }
  }

  .active,
  .activeIconColor {
    color: $Orange;
  }

}

.static-page-innerbox {
  .policy_terms_title {
    display: flex;
    width: 100%;
    padding: 0 0 40px;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    align-self: stretch;
    color: var(--primary-color);
    font-family: Outfit;
    font-size: 64px;
    font-style: normal;
    font-weight: 500;
    line-height: 85%;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 85%;
      font-size: 40px;

    }

    svg {
      min-width: 72px;
      max-width: 72px;
      stroke-width: 1.4px;
    }
  }

  ul {
    span {
      font-size: 17px;
      font-weight: 500;
      color: $Dark;
    }
  }

  li {
    left: 16px;
    position: relative;
    list-style-type: disc;
  }
}

.tab-content-hero {
  p {
    font-size: 18px;
    line-height: 150%;
  }

}

.visible-tab {
  display: block;
}

.hidden-tab {
  display: none;
}




//----------------------------------------------------------------
@media screen and (max-width: 500px) {

.error-page-box {
    .logo-box {
      h3 {
        font-size: 40px !important;
      }
      h4 {
        font-size: 30px !important;
      }
    }
    .error-page_btn {
      min-height: 60px !important;
    }
  }
  .error-page-logo_hor {
    width: 380px;
    height: auto;
    color: $Dark;
    display: none !important;
  }
  .error-page-logo_ver {
    width: 300px !important;
    display: flex !important;
  }


.soon-page {

  .logo-box {

    h3 {
      font-family: Outfit;
      font-size: 76px !important;
      font-weight: 800;
      line-height: 100%;
      text-align: center;
      color: $Dark;
    }

    h4 {
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 48px !important;
      font-weight: 900;
      line-height: 100%;
      text-align: center;
      color: $Dark;
      letter-spacing: -2px;
    }
  }

  .soon-page-logo {
    width: 240px !important;
    height: auto;
    color: $Dark;
    display: flex;
    // padding-bottom: 16px;
  }
}

}
//----------------------------------------------------------------------
@media screen and (max-width: 960px) {

  .sp-inner-content-box {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 24px;
    background-color: $Grey-00;
    padding: 16px;
    border-radius: 32px;

    .sp-inner-content {
      padding: 0 16px 24px;
    }
  }

  .contact-cont-box {
    display: flex;
    flex-direction: column;
    gap: 30px
  }

  .contact-page-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 32px;
    max-width: 688px;
  }

  .contact-form-box {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .contact-radio-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px 12px;

    .radiobox {
      gap: 8px;
    }
  }

  .contact-input-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .phone-input {
      width: 100% !important;
    }
  }

  .contact-btn-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 20px;
    margin-top: 12px;

    .checkbox {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      // width: 200px;

      .checkbox-right-icon {
        color: var(--primary-color);
        flex-shrink: 0;
        width: 40px;
        height: 40px;
      }
    }

    .contact-btn {
      width: 100%;
      height: 56px;
    }

    .contact-checkbox-icon {
      // color: #00E165;
      width: 40px;
    }
  }

  // .contact-text {
  //   height: 60px;
  // }

  
  .static-page-privacy-hero {

    .static-page {
      padding: 24px 32px 80px;
      width: 100%;
      max-width: 560px;
      gap: 32px;

      .static-page-innerbox {
        display: flex;
        flex-direction: column;
        gap: 24px;
        width: 100%;
      }
    }

  }
  .static-page-hero {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .static-page {
    padding: 20px 30px 40px !important;
    width: 100%;
    max-width: 640px;
    gap: 20px !important;
  }

  .sp-parag-box {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .paragraph-text-cont {
    order: 2;
  }

  // .paragraph-image {
  //   width: 100%;
  //   height: 300px;
  //   order: 1;
  // }

  .Parag-imag {
    order: 1;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sp-inner-content {
    order: 2;
  }

  .aboutus-slogan {
    font-size: 36px;
  }

  .slogan-box {
    padding: 70px 0px 80px 0px;

    h3 {
      font-size: 50px;
      font-weight: 500;
    }
  }

  .default-cont {
    display: flex;
    gap: 28px;
    flex-direction: column;
    align-items: stretch;

  }

  .big-image-box {
    padding: 0px;

    .big-image {
      // max-width: 380px;
    }
  }

  .paragraph-title {
    font-size: 32px;
  }

  .orderlist-item-desc {
    margin-left: 0px;
  }

  .orderlist-item-text {
    font-size: 24px;
  }

  .page-title-box {
    svg {
      width: 56px;
    }

    h2 {
      font-size: 34px;
      }
      }
      
      
      .paragraph-text-cont {
        min-width: 100%;
      }
      
      .tab-hero {
        flex-wrap: wrap;
      }
      
      .services-box{
        display: flex;
        flex-direction: column !important;
        gap: 24px;
        align-items: center;
        justify-self:center;
        margin: 32px 0;
      
        img{
          width: 100%;
          min-width: 320px;
          // max-width: 600px !important;
        }
      }

      .big-image-box {
        // width: 440px !important;

       
        .big-image {
          position: relative;
            width: 130%;
            object-fit: cover;
            padding: 40px 0;
        }
    }
}

//----------------------------------------------------------------

@media screen and (max-width: 1160px) {
  .sp-inner-content {
    padding: 24px 0;

    .newsletter-btns-box {
      flex-direction: column;

      .newsletter-btn {
        width: 100%;
      }
    }
  }

  .services-box{
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    align-items: center;
    justify-self:center;
    margin: 32px 0;
  
    img{
      width: 100%;
      max-width: 560px;
    }
  }

}