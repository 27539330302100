@import "variables";



.big_title{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    color: var(--def-orange, #FF6956);
    text-align: center;
    font-family: outfit;
    font-size: 50px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    width: 100%;
    padding: 48px 24px;
    letter-spacing: -0.6px;
    // background-color: #F5F6FA;

    svg{
        color: $Orange;
        width: 72px;
        height: 72px;
    }

}

.to_top{
width: 100%;
padding: 44px 0 0;
display: flex;
align-items: center;
justify-content: center;

.to_top_btn{
    width: 320px;
    height: 54px;
    background-color: $White;
   border: 2px solid $Grey_05;
   color: $Grey_05;

   &:hover{
    background-color: $Text_color;
    border: 2px solid $Text_color;
    color: $White;

   }
}
}

.static-page {
    display: flex;
    flex-direction: column;
    gap: 30px !important;
    padding: 32px 88px 125px;
    width: 1160px;
}

.slogan-box {
    padding: 40px 0px;
    display: flex;
    justify-content: center;

 h3 {
        color: var(--def-orange, #FF6956);
        text-align: center;
        font-family: outfit;
        font-size: 64px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        max-width: 530px;
        letter-spacing: -0.6px;
    }
}

.content-wrapper_centered {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    width: 100%;
    height: 100%;
    background-color: $Grey-00;
    overflow: scroll;
    overflow-x: auto;
  }

.signup_soon_box{
    max-width: 760px;
    margin: 32px;
    background-color: white;
    border-radius: 24px;
    padding: 0 0 56px 0;
}
.soon-logo-hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    color: $Orange;
    padding: 0;
    margin-bottom: 36px;
    width: 100%;
    min-width: 254px;
    background-color: $Orange;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: 220px;
    overflow: hidden;
    position: relative; 

    .illus_bg{
        width: 390px;
        position: absolute;
        top: -64px;
        left: -64px;
        animation: rotate360 20s infinite linear;
      }

    .soon-card-title {
        color: var(--def-orange, #FF6956);
        font-family: Outfit;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;

        &[data-lang=jp] {
            font-family: 'M PLUS 1p', sans-serif;
            font-weight: 700;
            font-size: 40px;
            line-height: 100%;
        }
    }

    .soon-logo {
        height: 60%;
        width: 100%;
        z-index: 2;
        margin: auto;
        padding-top: 12px;

    }
}

.soon-card-window {
    flex-direction: column !important;
    align-items: center !important;
    justify-self: center !important;
    width: 680px !important;
    max-width: none;
    padding: 0 !important;
}

.soon-paragraph {
        color: var(--def-darker, $Text_color);
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 130%;
        padding: 0 80px;
        display: flex;
        flex-direction: column;
        &[data-lang=jp] {
            font-family: 'M PLUS 1p', sans-serif;
            font-weight: 400;
            font-size: 17.4px;
            line-height: 148%;
            letter-spacing: 1.3px;
        }
    
 
        span{
            font-weight: 500;
            color: $Orange;
        }
ol{
    padding:12px 0 0;
}
      ol li {
        list-style: none;
        position: relative;
        padding: 6px 0 0px 22px;    
      }
      
      ol li::before {
        content: '＊';
        color: #FF6956;
        position: absolute;
        top: 4px;
        left: 0;
        font-size: 16px;
        font-weight: 600;
      }

      h4{
        width: 100%;
        text-align: left;
        padding: 8px 24px 0;

        a{
            color: $Orange;
        }
      }
}


.soon-paragraph-title {
    color: $Orange;
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 6px;

    &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-size: 19px;
        margin-bottom: 10px;
    }
}

.soon-form-cont {
    display: flex;
    padding: 40px 12%;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: 19px;
    align-self: stretch;
    background-color: #F5F6FA;
    margin: 32px 80px;
    border-radius: 24px;
}


.soon-inputs-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: center;
    max-width: 420px;
    width: 100%;
}

.soon-paragraph-small {
    color: $Grey_07;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin-bottom: 6px;
    padding: 0px 24px;

span{
    color: $Orange;
    font-weight: 500;
    cursor: pointer;

    &:hover{
        color: $Dark;
    }
}
    &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-size: 15px;
        margin-bottom: 10px;
        line-height: 130%;

    }
}

.soon-username-sample {
    background-color: #F5F6FA;
    display: flex;    padding: 10px 32px 13px;
    justify-content: center;
    align-items: center;
    border-radius: 40px;
    color: $Orange;
    font-family: Outfit;
    font-size: 20px;
    font-weight: 300;
    line-height: 120%;

    &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
    }

    span {
        color: $Grey-06;
    }
}

.soon-card-window-cont {
    align-items: baseline;
    padding: 40px 0;
    .popup-card{
    padding: 0px 0  72px !important;
    }
}



.input-comingsoon {
    display: flex;
    padding: 12px;
    align-items: center;
    gap: 4px;
    border-radius: 58px;
    border: 1.5px solid $Grey_02;
    background: $White;
    width: 100%;
    height: 54px;
    color: $Orange;

    #email {
        font-family: Outfit;
        font-size: 19px;
        font-weight: 400;

        &[data-lang=jp] {
            font-family: 'Outfit', sans-serif;
        }
    }

    .input-left-icon {
        height: 26px;
        width: 26px;
        color: $Grey_04;
        stroke-width: 1.5px;
        flex-shrink: 0;
    }

    .input-body {
        border: none;
        width: 100%;
        color: $Text_color !important;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;
        background-color: #ffffff00;



        &[data-lang=jp] {
            font-family: 'M PLUS 1p', sans-serif;
            font-weight: 500;
            color: $Dark;
            font-size: 17px;
        }

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: $Grey_05;
            font-family: Outfit;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 100% !important;

            &[data-lang=jp] {
                font-family: 'M PLUS 1p', sans-serif;
                font-size: 28px;
                font-weight: 500;
                line-height: 130%;
            }
        }


    }

    &.input-error {
        border-color: $Red;
    }

    &.input-disable {

        background: $Grey-00;
        border-color: $Grey-01;

        .input-left-icon {
            color: $Grey-04;
        }

    }

    &:focus-within {
        border-color: var(--primary-color);

        .input-left-icon {
            color: var(--primary-color);
        }
    }

    .request-dot {
        width: 10px;
        height: 10px;
        margin-left: 20px;
        ;
        color: $Orange;
        border-radius: 50%;
    }
}

.button-comingsoon {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    padding: 12px 18px 12px 24px;
    border: none;
    border-radius: 32px;
    background-color: var(--primary-color);
    color: $White;
    height: 54px;
    gap: 15px;
    width: 100%;
    text-align: left;
  
    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 400;
      line-height: 100%;
    }
  
    .button-left-icon,
    .button-right-icon {
      height: 27px;
      width: 27px;
      flex-shrink: 0;
      stroke-width: 1.6px;
    //   display: flex;
      align-items: center;
    }
  
    span {
      width: 100%;
    }
  
    &:hover {
      background-color:  $Text_color;
    }
  }
p{
    text-align: left !important;
}


.soon-button {
    height: 54px;
    max-width: 420px;
    margin: 8px;
    
    span{
        font-family: 'Outfit', sans-serif;
        font-size: 18px;

        &[data-lang=jp] {
            font-size: 17px;
            font-family: 'M PLUS 1p', sans-serif;
            font-weight: 400;
            line-height: 100%;
          }
    }
}

.button-lined{
    background-color: $White;
    border: 2px solid $Grey_03;
    color: $Orange;
    margin: 16px 32px 0;
    align-self: center;
    line-height: 90%;
    width: 360px;
    height: 54px;

    span {
        font-weight: 400;
    }
    &:hover{
        border: 2px solid $Dark;
        color: $White;
    
    }
}

.soon-form-cont{

.input {
    height: 54px; 
}

.select-container .select-display {
    height: 54px;

}

.dropdown-select {
    height: 54px;
}
}

.soon-drop-icon{
    width: 25px;
        stroke-width: 2px;
        color: #cccede;
        margin-right: 5px;
}
.soon-email-input{
background-color: #fff;
width: 100% !important;
max-width:  none;
}
.soon-username-input {
background-color: #fff;
width: 100% !important;
max-width: none;
}
.soon-location-input {
background-color: #fff !important;
}
.soon-role-input {
background-color: #fff !important;
}
// .soon-button{
//     height: 50px;
// }
.soon-button-done{
background-color: $Green !important;
}

//   -----------------------------------------------------------------

@media screen and (max-width: 960px) {

    .slogan-box h3 {
        font-size: 50px;
        line-height: 96%;
    }

    .static-page {
        display: flex;
        flex-direction: column;
        gap: 24px !important;
        padding: 32px 28px 64px !important;
        width: 100%;
    }

    .soon-paragraph {
        color: var(--def-darker, $Text_color);
        font-family: Outfit;
        font-size: 16px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 150% !important;
        padding: 0;
    }

    .soon-inputs-cont {
        max-width: 420px;
    }
    .signup_soon_box{
        width: 92%;
        max-width: 640px;
        margin: 4px;
        background-color: white;
        border-radius: 24px;
        padding: 0 0 56px 0;
        display: flex;
        align-items: center;
        justify-items: center;
        flex-direction: column;
    }

    .soon-logo-hero {
      
        height: 180px;
        
        .soon-logo {
         
            height: 55%;
            width: 100%;
            z-index: 2;
            margin: auto;
            padding-top: 12px;
    
        }

        .soon-card-title {
            color: var(--def-orange, #FF6956);
            font-family: Outfit;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: 90%;
            text-align: center;


            &[data-lang=jp] {
                font-family: 'M PLUS 1p', sans-serif;
                font-weight: 600;
                font-size: 40px;
                line-height: 100%;
            }
        }


    }
    

    .soon-card-window-cont {
        align-items: baseline;
        padding: 0 16px;
        .popup-card{
        padding: 0px 0  72px !important;
        }
    }
    

    .input-comingsoon {
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 4px;
        border-radius: 58px;
        border: 1.5px solid #ebebf9;
        background: #FFFFFF;
        max-width: 500px;
        height: 54px;
        color: #FF6A56;
    }
    .button-comingsoon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 17px;
        padding: 12px 18px 12px 24px;
        border: none;
        border-radius: 32px;
        background-color: $Orange;
        color: $White;
        height: 54px;
        gap: 15px;
        width: 100%;
        text-align: left;
        max-width: 500px;
      
        &[data-lang=jp] {
          font-family: 'M PLUS 1p', sans-serif;
          font-weight: 400;
          line-height: 100%;
        }
    }
    

    .popup-container .popup-card {
        // position: relative;
        // display: flex;
        // padding: 32px;
        // flex-direction: column;
        // align-items: center;
        // gap: 16px;
        // width: 92% !important;
        // max-width: 600px;
        // margin: 16px 0;
        // border-radius: 24px;
        // background: #FFFFFF;
    }

    .soon-paragraph-cont{
        margin: 0 5%;
        max-width: 540px;
        .button {
            height: 54px;
            width: 60%;
            min-width: 240px;
            
        }
    }
    .soon-form-cont {
        display: flex;
        padding: 32px 8%;
        flex-direction: column;
        align-items: center;
        gap: 19px;
        align-self: stretch;
        background-color: #F5F6FA;
        border-radius: 0px;
        margin: 32px 4%;
        border-radius: 24px;    }
 

}



