@import "variables";


//---------------- HEADER ---------------------------

.header_hero {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 130px;
    padding: 0 4%;
    border-top: 8px solid $Orange;
    border-bottom: 1px solid $Grey_03;
    // margin-bottom: 48px;


    .logo_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        font-size: 34px;
        font-weight: 900;
        color: $Orange;
        cursor: pointer;

        svg {
            color: $Orange;
            height: 68px !important;
            transition: color 0.2s ease;
        }

        .logo_type {

            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 0px;
            line-height: 90%;

            span {
                color: $Dark;
                transition: color 0.2s ease;
            }

            &:hover {
                color: $Dark;

                svg {
                    color: $Dark;
                }
            }
        }
    }

    .menu_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 16px;

        .menu_btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0px;
            font-size: 18px;
            font-family: Mont-M;
            line-height: 100%;
            font-weight: 500;
            color: $Dark;
            cursor: pointer;

            svg {
                height: 44px;
            }

            &:hover {
                color: $Orange;
                transition: color 0.1s ease;

                svg {
                    color: $Orange;
                    transition: color 0.1s ease;

                }
            }
        }

        .uud{
            padding: 0 0 0 10px;
            gap: 4px;
            font-family: Mont-B;
            color: $Grey-04;
            svg {
                height: 48px;
            }

        }
    }
}

.big_img_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 400px;
    background-color: #513C6A;
    margin-bottom: 40px;

    img {
        height: 100%;
    }


}

//---------------- CONTENT ---------------------------

.event_hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 88px;

    .forms_hero {
        width: 100%;
    }

    //---------------- INFO ---------------------------

    .info_hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        // padding: 64px 0;
        max-width: 1200px;
        font-family: 'M PLUS 1p', sans-serif;
        color: $Dark;


        .date_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 72%;
            min-width: 560px;
            font-family: Mont-M;
            flex-wrap: wrap;

            .date {
                font-size: 64px;
                font-weight: 700;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 5px;
                color: $Orange;

                .m_y {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 27px;
                    line-height: 85%;
                    color: $Dark;

                    h5 {
                        font-size: 20px;
                        color: $Grey_04;
                    }

                }
            }

            .day_time {
                font-size: 20px;
                font-weight: 700;
                display: flex;
                flex-direction: column;
                align-items: center;
                line-height: 80%;
                gap: 8px;
                color: $Grey_05;
                font-family: Mont-M;

                .time {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: $Dark;
                }
            }

            .place {
                font-size: 20px;
                font-weight: 500;
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 80%;
                gap: 8px;
                color: $Dark;
                font-family: 'M PLUS 1p', sans-serif;
                // flex-basis:auto; 

                span {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    gap: 6px;

                    h5 {
                        color: $Grey-06;
                        font-size: 18px;
                    }
                }

                .price {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1px;
                    color: $Orange;
                    border: 2px solid $Grey_04;
                    border-radius: 6px;
                    padding: 6px 4px;
                    font-size: 13px;
                    font-weight: 600;

                    h5 {
                        font-size: 16px;
                        font-weight: 800;
                        color: $Orange;
                    }
                }
            }
        }

        .title {
            font-size: 50px;
            font-weight: 700;
            line-height: 110%;
            text-align: center;
            max-width: 1000px;
            padding: 0 40px;
        }

        .tags_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 6px;
            flex-wrap: wrap;
            padding: 0 16px;
            max-width: 800px;

            span {
                border: 2px solid $Grey_02;
                color: $Grey_06;
                border-radius: 10px;
                font-size: 17px;
                font-weight: 400;
                font-family: Mont;
                padding: 4px 10px 2px;
            }

        }

        .join_btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 2px;
            width: 260px;
            height: 58px;
            color: $White;
            background-color: $Orange;
            border-radius: 12px;
            padding: 0 20px 0 18px;
            margin: 32px 0 56px 0;
            font-size: 20px;
            font-weight: 400;
            font-family: 'M PLUS 1p', sans-serif;
            cursor: pointer;

            svg {
                height: 44px;
                stroke-width: 1.2px;
            }

            &:hover {
                color: $Orange;
                background-color: $Dark;
                transition: color 0.1s ease;

            }

        }

        .image_box {
            .big-image-box .big-image {
                width: 100%;
                object-fit: cover;
                padding: 0;
            }
        }
    }


    .event_content_hero {
        display: flex;
        flex-direction: column;
        align-items: start;
        // gap: 24px;
        padding: 0 8%;
        width: 100%;
        max-width: 1140px;
        font-family: 'M PLUS 1p', sans-serif;
        color: $Dark;

        .text_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 10px;
            flex-wrap: wrap;
            font-size: 18px;
            line-height: 160%;

            .title {
                font-size: 32px;
                font-weight: 700;
                line-height: 110%;
                color: $Orange;
                font-family: Mont-M;
                width: 100%;

            }

            .list_box {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: start;
                // gap: 10px;
                // padding: 8px 0 0px 0;
                line-height: 140%;
                flex-basis: 100%;
                border-top: 1px solid $Grey_03;

                .list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: start;
                    gap: 4px;
                    flex-wrap: wrap;
                    width: 100%;

                    border-bottom: 1px solid $Grey_03;
                    padding: 12px 0;

                    span {
                        color: $Orange;
                        // line-height: 100%;
                        font-weight: 500;
                        flex-wrap: nowrap;
                        text-wrap: nowrap;
                    }
                }

            }
        }

        .image_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .big-image-box .big-image {
                width: 100%;
                object-fit: cover;
                padding: 0;
            }

        }
    }

    .shorter {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-content: start;
        // gap: 10px;
        flex-wrap: wrap;
        font-size: 18px;
        line-height: 160%;
        background-color: $Orange !important;
        color: $Dark !important;

        .text_box {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 10px;
            width: 100%;
            max-width: 560px;
            font-family: "M PLUS 1p", sans-serif;
            // padding: 24px 0;

            .title {
                color: $Dark;
            }

        }

        .sp-inner-content {
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;
            gap: 20px;
            width: 100%;
            // max-width: 560px;
            padding: 16px 0 0 0;


            .input {
                display: flex;
                padding: 12px 12px 12px 14px;
                align-items: center;
                border-radius: 12px;
                background: #FFFFFF;
                width: 100%;
                height: 56px;
                // color: $Green ;
                margin-top: 2px;
                border: $White 2px solid;

               

                input {
                    // font-family: "M PLUS 1p", sans-serif;
                    font-size: 19px;
                    font-weight: 400;

                    &::placeholder {
                        font-size: 18px;
                    }

                }

                &:focus-within {
                    border-color: $Dark;
                    border: 2px solid $Dark;

                    .input-left-icon {
                        height: 26px;
                        width: 26px;
                        color: $Dark;
                        stroke-width: 1.5px;
                        flex-shrink: 0;
                    }

                    .textarea-left-icon {
                        color: $Orange;
                    }
                }

            }

            .newsletter-btns-box {
                flex-direction: row;

             .newsletter-btn-success {
                    background-color: #00E165 !important;
                    &:hover {
                        // color: $White;
                        background-color: $Dark !important;
                    }

                }
            }

            .newsletter-btn {
                width: 160px;
                height: 56px;
                border-radius: 13px;
                padding: 0 12px 0 16px;
                font-size: 18px;
                font-weight: 400;
                height: 58px !important;
                font-family: 'M PLUS 1p', sans-serif;
                background-color: $Dark;

                .button-right-icon {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    height: 30px;
                    width: 30px;
                    flex-shrink: 0;
                    stroke-width: 1.3px;
                    align-items: center;
                }

                &:hover {
                    color: $Orange;
                    background-color: $Dark;
                    transition: color 0.1s ease;
                }

            }

        }
    }

.noevent {

    display: flex;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 48px;
    flex-wrap: wrap;
    font-size: 18px;
    line-height: 160%;
    background-color: $White !important;
    color: $Dark !important;

    .illus {
        width: 60%;
        max-width: 240px;
        color: $Orange;
    }

    .text_box {
        gap: 0px;
    }

    h5 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        gap: 10px;
        font-size: 56px;
        font-weight: 800;
        line-height: 100%;
        text-align: center;
        color: $Orange !important;
        padding: 0 24px;

        span {
            font-size: 67px;
            font-weight: 900;
            color: $Dark !important;
        }
    }

    .event_content_hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        width: auto !important;
        background-color: $Grey_00;
        padding: 40px;
        padding-bottom: 40px !important;
        border-radius: 16px;
    }

    .sp-inner-content .newsletter-btn {
        width: 160px;
        border-radius: 13px;
        padding: 0 12px 0 16px;
        font-size: 18px;
        font-weight: 400;
        height: 58px !important;
        font-family: "M PLUS 1p", sans-serif;
        background-color: $Orange
    }
}

.event_content_hero_ver {
    flex-direction: row;

    .text_box {
        width: 70%;
    }

    .image_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 35%;
        height: 240px;

    }
}    .event_content_hero_form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: $White;
        padding: 64px 0;
        background-color: #F5F6FA;

        .event_content_hero {
            padding-bottom: 0;
        }

    }
}

//---------------- PAST EVENTS ---------------------------
.past_card_list_hero{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 92%;
    max-width: 900px;
    padding: 0;
}
 .title_page {
    font-size: 32px;
    font-weight: 700;
    line-height: 110%;
    color: $Orange;
    font-family: Mont-M;
    width: 92%;
    max-width: 900px;
    span{
        color:$Dark;
    }
}
.past_card_hero {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    height: 240px;
    min-height: 200px;
    font-family: 'M PLUS 1p', sans-serif;
    color: $Dark;
    border: $Grey_03 1px solid;
    padding: 0 0 0 24px;

    .past_img_box {
        width: 90%;
        min-width: 340px;
        height: 100%;
        display: flex;

        .big-image {
            height: 100%;
            object-fit: cover;
            padding: 0;
        }
    }

    .space{
        height: 100%;
        background-color: aqua;
    }

    .past_card_info_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        // justify-content: space-between;
        gap: 12px;
        height: 100%;
        width: 100%;
        padding: 24px 0;

        .date_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            font-family: Mont-M;
            flex-wrap: wrap;

            .date {
                font-size: 50px;
                font-weight: 700;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                gap: 6px;
                color: $Orange;
                height: 44px;
                line-height: 85%;

                .m_y {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: 22px;
                    line-height: 85%;
                    color: $Dark;
                    gap: 2px;

                    h5 {
                        font-size: 18px;
                        color: $Grey_04;
                        line-height: 85%;

                    }

                }
            }

            .place {
                font-size: 16px;
                font-weight: 500;
                display: flex;
                flex-direction: row;
                align-items: center;
                line-height: 80%;
                gap: 8px;
                color: $Dark;
                font-family: 'M PLUS 1p', sans-serif;

                span {
                    display: flex;
                    flex-direction: column;
                    align-items: end;
                    gap: 6px;
                    padding: 2px 0 0 0;


                    h5 {
                        color: $Grey-06;
                        font-size: 15px;
                        line-height: 80%;

                    }
                }

                .price {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 1px;
                    color: $Orange;
                    border: 2px solid $Grey_04;
                    border-radius: 6px;
                    padding: 4px 4px;
                    font-size: 12px;
                    font-weight: 600;

                    h5 {
                        font-size: 14px;
                        font-weight: 800;
                        color: $Orange;
                    }
                }
            }
        }

        .title {
            font-size: 26px;
            font-weight: 500;
            line-height: 120%;
            text-align: left;
       
        }

        .tags_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: start;
            gap: 4px;
            flex-wrap: wrap;

            span {
                border: 2px solid $Grey_02;
                color: $Grey_06;
                border-radius: 6px;
                font-size: 13px;
                font-weight: 500;
                font-family: Mont-M;
                padding: 4px 6px 1px;
            }

        }

        .join_btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 2px;
            width: 260px;
            height: 58px;
            color: $White;
            background-color: $Orange;
            border-radius: 12px;
            padding: 0 20px 0 18px;
            margin: 32px 0 56px 0;
            font-size: 20px;
            font-weight: 400;
            font-family: 'M PLUS 1p', sans-serif;

            svg {
                height: 44px;
                stroke-width: 1.2px;
            }

            &:hover {
                color: $Orange;
                background-color: $Dark;
                transition: color 0.1s ease;

            }

        }

        .image_box {
            .big-image-box .big-image {
                width: 100%;
                object-fit: cover;
                padding: 0;
            }
        }
    }


    &:hover {
        transform: translateY(2px);
        transition: all 0.2s ease;
        background-color: #FAFAFA;


    .title {
        color: $Orange;
        transition: all 0.2s ease;
   
    }

    }
}

// - - - - - - - - - - - - - - - -

.footer {
    width: 100%;
    height: 100px;
    border-bottom: 8px solid $Dark;

}

.contact_hero {
    padding: 32px 0;
    align-items: center;

    .input-body {
        border: none;
        width: 100%;
        color: #513C6A;
        font-family: "M PLUS 1p", sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;


    }

    .select-container .select-display {
        font-family: "M PLUS 1p", sans-serif;

    }

    .select-container .custom-dropdown .options .dropdown-option {
        font-family: "M PLUS 1p", sans-serif;
    }

    .event_content_hero {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 2px;
        padding: 0 64px;
        width: 100%;
        // max-width: 960px;
        font-family: "M PLUS 1p", sans-serif;
        color: #39274E;
    }

    .contact-form-box {

        width: 100%;
        max-width: 1200px;
        padding: 32px 0;


        .en {
            .input-body {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-items: center;

                font-family: Mont !important;
                line-height: 50%;
            }
        }

    }
}
.textarea {
    height: 340px !important;
.text-area {
    border: none;
    height: 100%;
    resize: none;
    font-family: Outfit;
    font-size: 18px !important;
    color: #513C6A;
    padding: 0 12px 0 12px;
    line-height: 170%;
}
}


.partner_hero {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 32px;
    width: 100%;
    padding: 16px 0 0 0;

    svg {
        height: 88px;
        color: $Dark;

        &:hover {
            color: $Orange;
            transition: color 0.2s ease;
        }
    }
}

.speaker {

    .text {
        display: flex;
        flex-direction: row;
        align-items: start;
        justify-items: start;

        .list_box {
            display: none !important;
        }

        .speaker_hero {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-items: center;
            gap: 16px;
            // min-width: 340px !important;
            padding: 0 0 0 8%;

            .speaker_img {
                svg {
                    width: 140px;
                    height: 140px;
                }
            }

            .speaker_name {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-items: center;
                font-size: 22px;
                font-weight: 600;
                color: $Dark;
                font-family: "M PLUS 1p", sans-serif;

                span {
                    font-size: 20px;
                    color: $Orange;
                    font-family: Mont-M;
                    font-weight: 500;



                }
            }

            .speaker_sns_box {
                display: flex;
                flex-direction: row;
                align-items: start;
                gap: 8px;

                svg {
                    width: 32px;
                    color: $Orange;
                    stroke-width: 1.5px;

                    &:hover {
                        color: $Dark;
                        transition: color 0.3s ease;
                    }
                }
            }
        }
    }
}

// ---------------- Form ---------------------------

.join_form_hero {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 24px;
    width: 100%;
    max-width: 560px;
    font-family: "M PLUS 1p", sans-serif;

    .join_form_box {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 24px;
        width: 100%;

        .join_inputs {
            display: flex;
            flex-direction: column;
            align-items: start;
            gap: 14px;
            width: 100%;

            .input {
                display: flex;
                padding: 12px 12px 12px 14px;
                align-items: center;
                border-radius: 12px;
                background: #FFFFFF;
                width: 100%;
                height: 56px;
                color: $Green ;
                margin-top: 2px;

                input {
                    font-family: "M PLUS 1p", sans-serif;
                    font-size: 18px;
                    font-weight: 400;

                    &::placeholder {
                        font-size: 18px;
                    }

                }

                &:focus-within {
                    border-color: $Orange;
                    border: 2px solid $Orange;

                    .input-left-icon {
                        height: 26px;
                        width: 26px;
                        color: $Orange;
                        stroke-width: 1.5px;
                        flex-shrink: 0;
                    }

                    .textarea-left-icon {
                        color: $Orange;
                    }
                }

            }

            label {
                padding: 0 16px;

            }

            // ---------------- Select ---------------------------

            .select-container {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                .disable-select {
                    background-color: #F5F6FA !important;
                }

                &.is-open {
                    .select-display {
                        border-color: $Orange;
                        stroke-width: 2px;
                    }

                    .select-left-icon {
                        color: $Orange !important;
                    }

                    .select-right-icon:not(.anime) {
                        stroke-width: 2px;
                        color: $Orange;
                    }
                }

                .select-display {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 12px 12px 12px 14px;
                    //   border-radius: 16px;
                    border: 1.5px solid $Grey_04;
                    background: $White;
                    color: $Text_color;
                    font-family: Mont-M;
                    font-size: 17px;
                    cursor: pointer;
                    color: $Grey_05;
                    height: 56px;
                    stroke-width: 1.6px;
                    gap: 4PX;
                    background-color: $White !important;

                    .select-show {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        img {
                            height: 26px;
                            width: 26px;
                            object-fit: cover;
                            border-radius: 50%;
                            border: 1.4px solid $Grey_02;
                        }
                    }

                    .select-placeholder {
                        display: flex;
                        align-items: center;
                        gap: 4px;

                        svg {
                            height: 26px;
                            width: 26px;
                            color: $Grey_04;
                        }

                        input {
                            border: none;

                            &:focus {
                                outline: none;
                            }
                        }
                    }

                    .select-left-icon {
                        height: 26px;
                        width: 26px;
                        color: $Grey_04;
                        stroke-width: 1.6px;
                        flex-shrink: 0;
                    }

                    span {
                        width: 100%;
                        text-align: left;

                        &.selected {
                            color: $Text_color;
                            line-height: 92%;
                        }
                    }

                    .select-right-icon {
                        margin-left: 10px;
                        width: 24px;
                        height: 24px;
                        flex-shrink: 0;
                        stroke-width: 2;
                        color: $Grey-04;
                    }

                    .select-right-icon {
                        margin-left: 10px;
                        width: 24px;
                        height: 24px;

                        &.anime {
                            transform: rotateZ(-30deg);
                            transition: all ease-in-out .3s;

                            &:hover {
                                transform: rotateZ(0deg);
                                color: var(--primary-color);
                            }
                        }
                    }

                }

                .custom-dropdown {
                    position: absolute;
                    top: 110%;
                    left: 0;
                    width: 100%;
                    border: 1.5px solid $Orange;
                    border-radius: 20px;
                    background: $White;
                    z-index: 1;
                    max-height: 282px;
                    overflow: hidden;
                    z-index: 10;

                    .options {
                        overflow: auto;
                        max-height: 280px;

                        .dropdown-option {
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            padding: 10px 15px;
                            cursor: pointer;
                            border: 0.2px solid $Grey_01;
                            color: $Text_color;
                            font-family: Mont-M;
                            font-size: 17px;

                            img {
                                width: 26px;
                                border-radius: 50%;
                                object-fit: cover;
                                border: 1px solid $Grey_03;
                            }

                            &:hover,
                            &:focus {
                                background-color: $Grey_00;
                            }
                        }
                    }
                }

                &.select-container-error {
                    .select-display {
                        border-color: $Red;
                    }

                    svg {
                        color: $Red ;
                    }
                }

            }

        }

        .join_btn_box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            width: 100%;

            .join_btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                gap: 2px;
                width: 100%;
                height: 56px;
                color: $White;
                background-color: $Orange;
                border-radius: 13px;
                padding: 0 13px 0 16px;
                font-size: 18px;
                font-weight: 400;
                font-family: 'M PLUS 1p', sans-serif;

                svg {
                    height: 40px;
                    stroke-width: 1.2px;
                }

                &:hover {
                    color: $Orange;
                    background-color: $Dark;
                    transition: color 0.1s ease;
                }
            }

            .clear {
                color: $Grey_05;
                background-color: transparent;
                border: 2px solid $Grey_05;
                width: 36%;
                padding: 0 10px 0 16px;

                svg {
                    height: 36px;
                    stroke-width: 1.2px;
                }

                &:hover {
                    color: $Orange;
                    background-color: $Dark;
                    transition: color 0.1s ease;
                    border: 2px solid $Dark;

                }

            }
        }

        .checked {
            background-color: $Orange !important;
        }

        .event-select {
            padding: 12px 12px 12px 14px;
            border-radius: 12px;
            height: 56px;
        }
    }

    .radio_hero {

        display: flex;
        flex-direction: column;
        justify-content: start;
        gap: 8px;
        font-size: 18px !important;
        font-weight: 400;
        font-family: 'M PLUS 1p', sans-serif;
        padding: 8px 14px 14px;
        width: 100%;

        .contact-radio-box {
            display: flex;
            flex-direction: row;
            justify-content: start;
            gap: 10px 24px;
            font-size: 18px;
            font-weight: 500;
            font-family: 'M PLUS 1p', sans-serif;
            width: 100%;
        }

        .radiobox-text {
            width: 100%;
            font-family: 'M PLUS 1p', sans-serif;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            text-align: left;
        }

        .radiobox-right-icon {
            color: $Grey-06;
            flex-shrink: 0;
            width: 26px;
            height: 26px;
        }


        .radio-box {
            width: 28px;
            height: 28px;
            padding: 0px;
            background-color: $White;
        }

        .radio-box svg {
            width: 16px;
            height: 11px;
            flex-shrink: 0;
        }
    }

    .sanka {
        padding: 0 16px 12px;

        .radiobox-right-icon {
            display: none;
        }
    }

}
.popup-container{

    .popup-top-bar {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px !important;
        width: 100%;

        .popup-logo svg {
            width: 160px !important;
            height: auto;
        }
        .popup-title {
            font-size: 28px !important;
            text-align: center !important;

        }
    }
    .contact-success-card{
        font-size: 18px !important;
        text-align: center !important;
    }
}


//----------------------------------------------------------------------
@media screen and (max-width: 800px) {

        .speaker {
    
            .text {
                display: flex;
                flex-direction: column !important;
                gap: 24px;
                padding: 0;
    
                .speaker_hero {
                    min-width: 100% !important;
                    padding: 0x;
                }
            }
    
        }
    
        .title_page {
            font-size: 32px;
            font-weight: 700;
            line-height: 110%;
            color: $Orange;
            font-family: Mont-M;
            width: 90%;
            max-width: 500px;
        }
    
        .past_card_hero {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0px;
            width: 90%;
            max-width: 500px;
            // max-width: 900px;
            height: 100%;
            // min-height: 200px;
            font-family: 'M PLUS 1p', sans-serif;
            color: $Dark;
            border: $Grey_03 1px solid;
            padding: 0;
    
    
            .past_img_box {
                width: 100%;
                min-width: 240px;
                height: 100%;
                max-height: 240px;
                display: flex;
            }
    
            .past_card_info_box {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                gap: 12px;
                height: 100%;
                width: 100%;
                padding: 24px;
            }
    
        }
    
        .sp-inner-content .newsletter-btns-box {
            flex-direction: row;
        }
    
}
//----------------------------------------------------------------------
@media screen and (max-width: 600px) {

    .noevent {
        gap: 24px !important;
   
    h5{

        font-size: 50px !important;
        span{
            font-size: 54px !important;
        }
    }

    }

    .header_hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        height: auto;
        padding: 16px 0;
        // margin-bottom: 24px;
    }

    .info_hero {
        gap: 36px !important;
        // padding: 32px 0 !important;

        .date_box {
            width: 88% !important;
            min-width: auto !important;

            .place {
                flex-basis: 100%;
                justify-content: center;
            }
        }

        .title {
            font-size: 44px !important;
            padding: 0 20px !important;
        }

        .join_btn {
            margin: 0 0 40px 0 !important;
        }


    }

    .join_form_hero .join_form_box .join_btn_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        gap: 12px;
        width: 100%;

        .clear {
            width: 100%;
        }
    }

    .event_content_hero_form {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: #FFFFFF;
        padding: 0 !important;
        background-color: #F5F6FA;
    }

    .event_hero {
        gap: 54px;

        .shorter {
            padding: 20px 0 0 0 !important;

            .sp-inner-content .newsletter-btn {
                width: 100%;

            }
        }
    }

    .event_content_hero_form .event_content_hero {
        padding-top: 48px !important;
        padding-bottom: 48px !important;


    }

    .event_hero .shorter .sp-inner-content .newsletter-btns-box {
        flex-direction: column;
    }
}