@import "variables";

body::-webkit-scrollbar {
  width: 0px;
  background-color: transparent; /* Set scrollbar background to transparent */
}

/* Customize scrollbar thumb */
body::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 8px; /* Round the corners of the scrollbar thumb */
}

/* Set scrollbar on top of the content without pushing it left */
body {
  margin-right: 0px; /* Adjust margin to accommodate scrollbar width */
  overflow-y: scroll; /* Always show the scrollbar */
}