$Orange: #FF6A56;
$Yellow: #FEA300;
$FF3A81: #FF3A81;
$Purple: #9648DE;
$Blue: #15A6F8;
$Green: #00E166;
$White: #FFFFFF;
$Text_color: #513C6A;
$Dark: #39274E;
$Red: #FF0044;
$Text_color_Orange: #D8412E;
$Greenblue: #4FDFC2;
$Grey_00: #F5F6FA;
$Grey_01: #F0F0F9;
$Grey_02: #ebebf9;
$Grey_03: #DEDEEB;
$Grey_04: #CCCEDE;
$Grey_05: #BBBDD6;
$Grey_06: #ABADC8;
$Grey_07: #878BBA;

$Background: rgba(92, 71, 115, 70%);
$Body_BackgroundColor: #EEEFF5;
$Box_Shadow: 0px 4px 2px rgba(0, 0, 0, 0.01), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07);


$Dark_Orange: #D8412E;
$Greenblue: #4FDFC2;
$Background: rgba(92, 71, 115, 70%);
$Body_BackgroundColor: #EEEFF5;


$Box_Shadow: 0px 4px 2px rgba(0, 0, 0, 0.01), 0px 2px 2px rgba(0, 0, 0, 0.04), 0px 1px 1px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.07), 0px 0px 0px rgba(0, 0, 0, 0.07);


$Height_Input: 46px;
$Height_Input_mob: 54px;
$Width_Default_Input: 100%;
$Radius_Input: 10px;
$FontSize_Input: 17px;


$WidthInput_Icon: 25px;
$HeightInput_Icon: 25px;
$WidthBtn_Icon: 27px;
$HeightBtn_Icon: 27px;
$Width_Icon_Avatar: 42px;
$Height_Icon_Avatar: 42px;


$Width_CardLogin: 460px;
$Height_CardLogin: auto;
$Card_Radius: 16px;
$LoginCards_Padding: 40px 48px;


$Outfit_Font: "Outfit";
$Font_Weight_Bold: 600;
$Font_Weight_Medium: 500;
$Font_Weight_Regular: 400;


$Width_Large_Btn: 100%;
$Height_Btn: 46px;
$Height_Btn_Mob: 54px;

$Width_Small_Btn: 144px;
$Padding_Btn: 12px 10px 12px 16px;
$FontSize_Btn: 17px;
$Radius_Btn: 10px;

$Width_Checkbox_Large: 36px;
$Height_Checkbox_Large: 24px;

$Mobile_Width: 768px;

$Width_Large_Card: 600px;
$Width_Small_Card: 100vw;


$MenuLeftBar_Width: 104px;
$MenuLeftBar_Height: 100vh;

$MenuRightBar_Width: 88px;
$MenuRightBar_Height: 100vh;
$MenuRightBar_BackgroundColor: $White;

$UserCard_Width: 264px;
$UserCard_Height: 380px;
$UserCard_MarginTop: 12px;
$UserCard_MarginSides: 36px;
$UserCard_MarginBottom: 38px;
$UserCard_BackgroundColor: $White;

$TermsCard_Padding: 48px;