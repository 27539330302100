@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";

.popup-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100dvw;
  background-color: rgba($color:  $Text_color, $alpha: 0.9);
  z-index: 99999;
  overflow-y: scroll;
  padding: 0 8px 0 16px;

  .popup-card {
    position: relative;
    display: flex;
    align-items: stretch;
    gap: 25px;
    width: 100%;
    margin: 12px;
    border-radius: 20px;
    background: $White;
    overflow: hidden;

    .close-btn {
      display: block;
      position: absolute;
      top: 10px;
      right: 10px;
      color: $Grey-03;

      &:hover{
        color: $Orange;
      }
    }

    .popup-top-bar {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
      width: 100%;

      .popup-title {
        display: flex;
        // padding: 16px 12px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: $Orange;
        text-align: center;
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;

        &[data-lang=jp] {
          font-family: 'M PLUS 1p', sans-serif;
          font-weight: 500;
          font-size: 25px !important;
          line-height: 130%;
          padding-top: 4px;
        }

      }

      .popup-logo {
        color: $Orange;
        // height: 84px;
        // background-color: antiquewhite;

        svg {
          width: 320px;
          height: auto;
        }
      }
    }

  }


  .mail_signup_card{
  gap: 0;
  }
  .avatar_box {
    position: relative;
    display: flex;
    padding: 40px 32px 40px 32px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 300px;
    max-width: 100%;
    margin: 12px;
    border-radius: 24px;
    background: $White;
  }
}

.popup-card-inner {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 20px;
  width: 100%;
  padding: 48px 54px;
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
}
.user_card_view_box{
  padding: 0;
  background-color: $Grey-01;
  padding: 40px 0;

}
.inner_card{
  padding: 48px 40px;
  width: 60%;
}
.popup-card-title {
  color: #FF6956;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.popup-card-description {
  color: $Text_color;
  leading-trim: both;
  text-edge: cap;
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding: 0px 15px;
}

.popup-checkbox-cont {
  display: flex;
  flex-direction: column;

  width: 100%;
}

.popup-card-close {
  position: absolute;
  stroke-width: 1.8px;
  color: $Grey-04;
  width: 40px;
  height: 40px;
  top: 15px;
  right: 15px;
  cursor: pointer;

  &:hover {
    color: var(--primary-color);

  }
}

.popup-card-cont {
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(57, 39, 78, 0.9);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;

}
.popup-card-image-cont{
  background-color: $Orange;
  height: 100%;
  width: 540px;
  overflow: hidden;
}
.popup-card-isolation{
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 520px;
}
.add_item_box{
  max-width: 932px;
}
.inner_box{
  background-color: #FF6956;
  padding: 0;
}
.mail_signup{
  max-width: 660px;
  height: fit-content;
}

.checkemail-popup-text-cont{
  display: flex;
  flex-direction: column;
  gap: 10px;
p{
  font-family: Outfit;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0em;
    text-align: left;
    color: #39274E;;
}
span {
  color: $Orange;
}
}

// - - - - - - - - - -  Media Query - - - - - - - - - -  //


@media screen and (max-width: 960px) {
.popup-card-inner {
  padding: 48px 10%;
}
.inner_box{
  background-color: #FF6956;
  padding: 0;
}

.popup-card-image-cont{
display: none;
}
.mail_signup {
  max-width: 480px;
}
.inner_card {
  padding: 48px 10%;
  width: 100%;
}
}