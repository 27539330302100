@tailwind base;
@tailwind components;
@tailwind utilities;

#layout,
#secondary-layout {
  width: 100%;
  position: fixed;
    height: 100%;
  overflow: hidden;

  .layout-container {
    display: flex;
    border-top: 6px solid var(--primary-color);
    border-bottom: 6px solid var(--primary-color);
    border-right: 6px solid var(--primary-color);
    height: 100%;
    width: 100%;

    .left-bar,
    .left-bar-secondary {
      display: flex;
      height: 100%;
      width: 128px;
      padding: 24px 16px 16px 24px;
      flex-direction: column;
      align-items: center;
      gap: 40px;
      flex-shrink: 0;
      background-color: var(--primary-color);
      overflow: scroll;
      overflow-x: auto;

      .left-bar-navigation {
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 14px;
      }

      .left-bar-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        min-height: 366px;
        height: 100%;
        width: 100%;

        span {
          text-wrap: nowrap;
          transform-origin: top left;
          transform: rotate(90deg) translateY(-175%);
          color: var(--def-white, #FFF);
          text-align: center;
          font-family: Outfit;
          font-size: 44px;
          font-style: normal;
          font-weight: 700;
          line-height: 72%;
          letter-spacing: 6.4px;
          white-space: nowrap;
        }
      }
    }

        .top-bar {
          display: none;
          width: 100%;
          height: 54px;
          padding: 4px 4px 4px 0;
          justify-content: space-between;
          align-items: center;
          flex-shrink: 0;
          background-color: var(--primary-color);
        }
    
        .setting_menu_box {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
    
          .setting_title_box {
            display: flex;
            flex-direction: row;
            // padding: 0 16px;
            gap: 4px;
    
            svg {
              width: 40px;
              stroke-width: 1.5;
              color: $Orange;
            }
          }
        }


    .action-menu {
      display: flex;
      z-index: 999;
      height: 100%;
      width: 348px;
      flex-shrink: 0;
      background-color: $White;
      border-right: 1px solid #e7e7f5;
      align-items: center;
      flex-direction: column;
      // padding: 32px 0;

      &.action-menu-default {
        // padding: 48px 32px 32px 32px;
      }

      .filter-items {
        padding: 32px;
        overflow-y: scroll;
        width: 100%;
        max-width: 960px;
        height: 100%;
      }

      &.action-menu-settings {
        padding: 0;
        width: 300px;
      }

      .close-btn {
        position: absolute;
        top: 6px;
        right: 6px;
      }
    }

    .profile-action-menu{
      width: 360px;
      overflow: overlay;
    }

    #servicez, #aboutus, #privacy ,#contact  {
      background-color: $White;
    }

    .content-wrapper {
      width: 100%;
      height: 100%;
      background-color: $Grey-00;
      // overflow: scroll;
      overflow-x: auto;
    }

    .overflow-none {
      overflow: hidden !important;
    }

    .right-bar-mobile-container {
      display: none;
    }

    .right-bar {
      display: flex;
      height: 100%;
      width: 104px;
      padding: 20px 0px 20px 0px;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      flex-shrink: 0;
      background-color: $White;
      transition: right .25s ease-in-out;
      border-left: 1px solid #e7e7f5;

      .layout-profile-image{
        height: 60px !important;
        width: 60px !important;

        svg{
          color: $Grey-04;
          height: 54px !important;
          width: 54px !important;
        }

        &:hover {
          svg{
          transform: scale(1.04);
          cursor: pointer;
          transition: all .03s ease-in-out;
          color: $White;
          height: 50px !important;
          width: 50px !important;
        }}
      }


      
      .right-bar-main-navigation {
        display: flex;
        align-items: center;
        flex-direction: column;
        display: flex;
        gap: 12px;
        color: $Grey_07;
        width: 100%;
        // height: 100%;

        .icon-number {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FF6956;
          border-radius: 50%;
          height: 19px;
          width: 19px;
          color: #fff;
          font-family: Outfit;
          font-size: 12px;
          position: absolute;
          left: -9px;
          border: 3px solid #FFFFFF;
        }
      }
        .access_box{
          display: flex;
          align-items: center;
          flex-direction: column;
          display: flex;
          gap: 5px;
          color: $Grey_07;
          width: 100%;
          // height: 100%;
        }
      .right-bar-bottom-part {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        height: 100%;
        justify-content: end;

        .right-bar-second-navigation {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
          color: $Grey_07;
        }

        .right-bar-copywrite {
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow: hidden;
          color: $Grey_05;
          text-align: center;
          text-overflow: ellipsis;
          whitespace: nowrap;
          font-family: Outfit;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          flex-shrink: 0;
         
        }
      }
      .extra-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        width: 100%;
        gap: 2px;
  
        &:hover {
          svg{
          transform: scale(1.15);
          color: $Orange;
          cursor: pointer;
          transition: all .09s ease-in-out;
        }
        }

        .rightbar_icon{
          stroke-width: 3.6px;
        }
  
        .extra-title{
          font-family: Outfit;
          font-size: 14.5px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
          flex-shrink: 0;
          width: 100%;
          text-align: center;
          display: none;
          width: auto;
  
        }
        svg{
          height: 32px;
          width: 32px;
          stroke-width: 1.4px;
          }
      }

    }

    .bottom-bar {
      display: none;
      width: 100%;
      height: 70px;
      padding: 8px 12px;
      justify-content: center;
      align-items: flex-start;
      gap: 20px;
      flex-shrink: 0;
      background-color: var(--primary-color);
    }


  }
}

//  --------------- Scroll Customization -------------------

::-webkit-scrollbar {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #39274e17;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width as needed */
}

// ----------------------------------------------------------

#secondary-layout .content-wrapper {
  display: flex;
  justify-content: center;
}

.user-card-favsCounts-boxs {
  display: flex;
  align-items: flex-start;
  margin-top: 24px;
  gap: 2px;

  .user-card-favsCount {
    cursor: pointer;
    display: flex;
    width: 48px;
    padding: 12px 0 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
    background-color: $Grey_00;

    &:hover {
      background-color: $Grey_01;
    }

    &:nth-child(1) {
      border-radius: 10px 0 0 10px;
    }

    &:nth-child(3) {
      border-radius: 0 10px 10px 0;
    }

    svg {
      width: 32px;
      height: 32px;
      color: $Orange;
    }

    .user-card-favsCount-title {
      color: $Text_color;
      text-align: center;
      font-size: 0.875rem;
      font-family: Outfit;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }

    .user-card-favsCount-number {
      color: $Text_color;
      text-align: center;
      font-size: 17px;
      font-family: Outfit;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
    }

    &.selected {
      background-color: $Orange;

      &>* {
        color: $White !important;
      }
    }
  }
}

//  --------------------------------------- MEDIA QUERY ------------------------------------

@media screen and (max-width: 960px) {
  .action-menu-settings {
    height: calc(100% - 124px) !important;
  }

  .settings-content-border {
    border: 7px solid var(--primary-color);
  }

  #layout,
  #secondary-layout {
    .layout-container {
      flex-direction: column;
      display: flex;
      border-top: none;
      border-bottom: none;
      border-left: 6px solid var(--primary-color);
      height: 100%;
      width: 100%;

      .left-bar,
      .left-bar-secondary {
        display: none;
      }

      .top-bar {
        display: flex;
        color: $White;

        .top-bar-left-icon {
          height: 36px;
          width: 36px;
          stroke-width: 1.6px !important;

          &:hover {
            cursor: pointer;
          }

        }

        .top-bar-title {
          display: flex;
          gap: 4px;
          text-align: center;
          font-family: Outfit;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%;
          text-transform: uppercase;

          .statistique {
            color: $Text_color;
          }
        }
      }

      .action-menu {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100% !important;

        &.show-mobile {
          left: 0%;
        }

        .close-menu {
          display: block;
          position: absolute;
          top: 10px;
          right: 4px;
          z-index: 10;
          color: $Grey_04;
          stroke-width: 1.5px;
          height: 56px;
          width: 56px;
        }
      }

      .right-bar {
        position: absolute;
        top: 0;
        right: -100%;
        z-index: 1001;
        display: flex;
        height: 100%;
        width: 100px;
        padding: 20px 0px 20px 0px !important;

        &.show-mobile {
          right: 0%;
        }
        .layout-profile-image{

          height: 68px !important;
          width: 68px !important;

        }
        .right-bar-bottom-part {
      
          .right-bar-second-navigation {
            gap: 6px;
          }}

        .extra-icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          width: 100%;
          gap: 4px;
    
          &:hover {
            svg{
            transform: scale(1.15);
            color: $Orange;
            cursor: pointer;
            transition: all .09s ease-in-out;
          }
          }
    
          .extra-title{
            font-family: Outfit;
            font-size: 14.5px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            flex-shrink: 0;
            width: auto;
            text-align: center;
            display: flex;

            &[data-lang=jp] {
              font-family: 'M PLUS 1p', sans-serif;
              line-height: 100%;
              font-weight: 600;
              font-size: 13px;
            }
    
          }
          svg{
            height: 38px;
            width: 38px;
            stroke-width: 1.4px;
            }
        }
  
      }







      .right-bar-mobile-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $Text_color, $alpha: 0.9);
        padding: 6px;
        color: $White;
        z-index: 1000;


        // svg {
        //   height: 58px;
        //   width: 58px;
        //   stroke-width: 1.4px;
        //   cursor: pointer;
        // }

        .switch-language-mobile {
          border: 1.6px solid #FFFFFF;
          background: none;
          color: #FFFFFF !important;
          margin-left: 13px;
          margin-bottom: 14px;
          width: 88px;
          height: 44px;
          font-size: 15px;

          &:hover {
            background-color: $White;
            color: $Text_color !important;
          }
        }
      }

      .bottom-bar {
        display: flex;
      }

      
    }
  }

  #secondary-layout .layout-container {
    border: 0;
  }

  .user-card-favsCounts-boxs {
  .user-card-favsCount {
    cursor: pointer;
    display: flex;
    width: 64px;
    padding: 12px 0 8px;
  }
}
}

.close-btn {
  cursor: pointer;
  display: none;
  top: 10px;
  right: 10px;
  z-index: 10;
  color: $Grey_04;
  stroke-width: 1.5px;
  height: 46px;
  width: 46px;

  &:hover {
    color: $Orange;
  }

  .close-btn {
    display: block;
    z-index: 10;
    color: $Grey_04;
    stroke-width: 1.4px;
    height: 48px;
    width: 48px;
  }

  #layout,
  #secondary-layout {

    .layout-profile-image {
      color: var(--primary-color);
      width: 72px !important;
      height: 72px !important;

      svg {
        height: 66px;
        width: 66px;
      }

      &:hover {
        color: $White;
      }
    }

    .profile-image-top {
      width: 42px !important;
      height: 42px !important;
      background-color: $White;

      svg {
        width: 36px;
        height: 36px;
        border-color: aquamarine;
      }

      &:hover {
        color: $White;
        color: var(--primary-color);

      }
    }
  }

  .user-card-favsCounts-boxs .user-card-favsCount {
    width: 88px;
    gap: 2px;

    .user-card-favsCount-title {
      font-size: 16px;
    }
  }

 


  #layout .layout-container .action-menu.action-menu-default {
    // padding: 32px;
  }

}
.content-wrapper-left{
  justify-content: start !important;
}


@media screen and (max-width:960px) {
  .border-orange {
    border: 6px solid var(--primary-color);
  }

    .close-btn {
        display:  block;
        top: 12px !important;
        right: 12px !important;
        width: 48px;
        height: 48px;
      }
}