@tailwind base;
@tailwind components;
@tailwind utilities;




.card-grid {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: $Grey-00;

}
.signup-experience-cont{
  display: flex;
  // margin-bottom: 8px;
  width: 100%;

}
.user-form-body-restriction-info{
  span{
    color: #ABACCA;
  }
}
.signup-experience-label {
font-family: Outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: $Text_color;
  // white-space: nowrap;
}

.settings-cont {
  max-width: 540px;

  // p {
  //   margin-left: 3px;
  // }

  .user-form-body-header {
    display: flex;
    flex-direction: column;
    align-items: normal;
    align-self: stretch;
    padding: 0 0 8px 0;
    gap: 10px;

    .user-form-body-header-title {
      color: var(--primary-color);
      font-family: Outfit;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 32px */
      width: 100%;
    }
  }
}


.settings-leftbar-link {
  display: flex;
  height: 64px;
  padding: 0px 16px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e7e7e7;

  &:hover {
    cursor: pointer;
    background-color: $Grey_00;
  }

  .settings-leftbar-link-text {
    color: $Text_color;
    font-family: 'outfit';
    font-weight: 400;
    font-size: 17px;
    
    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
    }
  }

  .settings-leftbar-link-icon {
    width: 30px;
    color: #ABACCA;
    stroke-width: 1.3px;
  }
}

.media-links-note {
  // display: contents;
  color: $Grey-06;
  // margin-left: 5px;
  font-size: 15px;
}
    .settings-uxers-cont{
      background-color: #fff;
      max-width: 540px;

    }
.settings {
  display: flex;
  max-width: 540px;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background-color: $White;
  // border-bottom: 1px solid #e7e7f5;

  .settings-card-cont {
    background-color: #EEEFF5;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
  }


  .settings-switch-cont {
    font-family: Outfit;
    font-weight: 500;
    color: $Text_color;
    padding: 24px 0;
    margin: 0px;
    max-width: 440px;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      line-height: 110%;
    }
  }

  .settings-input-cont {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    padding: 0px 60px 50px;
  }

  .hide{
    font-family: Outfit;
    font-size: 17px;
    
    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 120%;
  
    }
  }

}

.settings-title-cont {
  padding: 36px 72px 16px;
  width: 100%;
}

.settings-title {
  color: var(--primary-color);
  font-family: Outfit;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  /* 32px */
  width: 100%;
  // padding: 4px 20px;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
    font-size: 25px;
  }
}

.setting-text{
  width: 100%;
  padding: 14px 16px 4px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: $Text_color;
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
    font-size: 15.5px;
    line-height: 128%;
  }
}
.settings-email-email{
  font-family: Outfit;
  font-size: 17px;
  font-weight: 400;
  color: var(--primary-color) !important;
}
.settings-p{

      color: $Text_color !important;
      font-family: 'outfit' !important;
      font-weight: 400 !important;
      font-size: 17px !important;
      line-height: 120% !important;
      padding: 0 16px 8px !important;
      line-height: 120% !important;
      padding: 0 !important;
    
}
.close-btn-settings {
  position: absolute;
  right: 2px !important;
  top: 2px !important;
}

.uxer-form {
  background-color: #EEEFF5;
  width: 100%;
}

.filter-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  gap: 16px;
  align-items: center;

  .filter-items-category {
    cursor: auto;
    color: var(--primary-color);
    padding: 12px 0;
    gap: 2px;
    flex-direction: column-reverse;

    span {
      font-size: 32px;
    }

    svg {
      height: 88px;
      width: 88px;
    }
  }

  .filter-items-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 24px;
    width: 100%;

    .filter-top-inputs {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 8px;
    }

    .search-input {
      .input-right-icon {
        cursor: pointer;
        transform: rotateZ(0deg);
        transition: transform .2s ease-in-out;

        &:hover {
          transform: rotateZ(30deg);
          color: var(--primary-color);
        }
      }
    }
  }
}

.settings-edit-user-from {
  width: auto !important;
  max-width: 460px !important;
}

.join-uxer-button{
  width: 94%;
  min-height: 60px;
  background-color: var(--primary-color);
  color: $White;
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 106%;
  padding: 0 10px 0 24px;
  margin-top: 64px;
  transition: .15s ease-in-out;

  .button-right-icon {
    min-width: 44px !important;
    height: 44px;
    border-radius: 24px;
    stroke-width: 1.3px;
    background-color: $White;
    color: var(--primary-color);
    padding: 5px;
  }
  &:hover{
    transition: .15s ease-in-out;
      background-color: $Dark;
      color: $White;
      .button-right-icon {
        transition: .15s ease-in-out;
        color: $Dark;
      }
  }
}
.user-form-footer {
  // max-width: 460px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #fff;
  align-items: center;
  border-top: 1px solid #e7e7f5;
}

.user-form {
  display: flex;
  width: 540px;
  height: fit-content;
  // padding: 40px 64px 60px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  background-color: $White;
  max-width: 100%;

  &.user-form-uxer {
    padding: 0;
  }

  .user-form-top-bar {
    display: flex;
    align-items: center;
    justify-self: center;
    color: var(--primary-color);
    width: 100%;
    height: 88px;
    padding: 0 12px;

    .user-form-header-left-logo {
      width: 160px;
      height: auto;
    }

    .user-form-header-right-logo {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 6.098px;

      .user-form-logo-type {
        width: 78px;
        height: 24px;
      }
    }
  }
.experience_input{
  width: 140px;
}
  .user-form-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    // align-self: stretch;
    padding: 0px 68px 50px;

    p {
      color:  $Text_color;
      font-family: Outfit;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 108%;
      padding: 12px 20px 2px;
      width: 100%;
      gap: 12px;

      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-weight: 500;
        font-size: 15px;
        line-height: 128%;
      }
    }

    .terms{
      font-family: Outfit;
      font-size: 17px;

      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
      }
    }
    .user-form-body-header {
      display: flex;
      flex-direction: column;
      align-items: normal;
      align-self: stretch;
      padding: 0 0 8px 0;
      gap: 10px;

      .user-form-body-header-title {
        color: var(--primary-color);
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        width: 100%;
        padding: 40px 14px 0;

        &[data-lang=jp] {
          font-family: 'M PLUS 1p', sans-serif;
          font-weight: 500;
          font-size: 32px;
          line-height: 100%;
        }

      }

      .user-form-body-header-info {
        padding: 8px 16px;
        color:  $Text_color;
        font-family: Outfit;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        &[data-lang=jp] {
          font-family: 'M PLUS 1p', sans-serif;
          font-weight: 500;
          font-size: 16px;
          line-height: 130%;
        }

        .user-form-body-header-label {
          color: #ABACCA;
        }

        .user-form-body-header-date {
          color: var(--primary-color);
        }
      }
    }
  }
}

.settings-delete-button {
  background-color: $Grey-01;
  color: $Grey-06;
  width: 248px;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 400;
    font-size: 16px;

  }

  &:hover {
    color: #fff;
  }
}

.settings-delete-text {
  width: 100%;
  padding: 6px 88px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: $Grey-07;
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  
  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-size: 15px !important;
    line-height: 130% !important;
  }

}

.user-form-body-required {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  // padding: 0px 50px 0px 50px;

  .user-form-body-round-profile {
    display: flex;
    // padding: 0px 16px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    padding: 0 12px;

    .user-form-body-profile-chose {
      width: 106px !important;
      height: 106px !important;
      background-color: $Grey_03;
    }
  }

  .user-form-body-login {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    align-self: stretch;
  }

  .user-form-body-more-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    align-self: stretch;
    color: $Text_color;
    font-family: Outfit !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 110% !important;

  //   [data-lang=jp] {
  //     font-family: "M PLUS 1p", sans-serif !important;
  //     font-weight: 500;
  //     font-size: 15px;
  //     line-height: 130%;
  // }

    .user-form-col-2 {
      display: flex;
      gap: 8px;
      width: 100%;
    }

    .user-form-group-box {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 8px 0  20px;
      // background-color: aliceblue;
      gap: 16px;


      .user-form-group {
        display: flex;
        padding: 0 6px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;

        .user-form-label {
          display: flex;
          width: 100%;
          padding: 6px 16px 0;
          align-items: center;
          gap: 6px;
          align-self: stretch;
          color: var(--primary-color);
          font-family: Outfit;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 116%;

          &[data-lang=jp] {
            font-family: 'M PLUS 1p', sans-serif;
            font-weight: 500;
            font-size: 18px;
            line-height: 116%;
          }
        }
      }

      .user-form-body-restriction-info {
        display: flex;
        padding: 8px 14px;
        align-items: center;
        gap: 6px;
        align-self: stretch;
        color:  $Text_color;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
      }

    }

  }

  .user-form-body-more-info{


  }
}

.text-grey-default {
  color: #ABACCA;
  word-wrap: break-word;
}

.icon-stroke {
  stroke-width: 1.5px;
}

.border-bottom-light {
  border-bottom: 1px solid #e7e7f5
}

.user-form-body-socialmedia, .user-form-body-more-info {
  display: flex;
  justify-content: left;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap;

  .user-form-body-socialmedia-title {
    width: 100%;
    padding: 14px 16px 4px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color:  $Text_color;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 17.6px */

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 121%;
    }
  }


  .input-half {
    width: calc(50% - 5px);
  }

  .option {
    color: $Grey-04;
  }

}

.user-form-body-buttons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  flex-wrap: wrap-reverse;
  margin: 8px 0;

  .outline-button {
    background: none;
    color: var(--primary-color);
    width: auto;
    gap: 10px;
    border: 1.4px solid var(--primary-color);
    width: min-content;
  }
}

.w-auto {
  width: auto;
}

.user-form-bad-news-paragrah {
  display: flex;
  padding: 0px 16px 8px 16px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  color:  $Text_color;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  /* 17.6px */
}

.check-full {
  .checkbox {
    // width: 100%;
    
  }
}

.check-half {
  flex-direction: row;
  flex-wrap: wrap;

  .checkbox {
    // width: 136px;
    &[data-lang=jp] {
    
    }
  }
}

.uxer-grid {
  padding: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background-color: $Grey-00;
}

@media screen and (max-width: 960px) {
  
  .settings-uxers-cont {
    background-color: #fff;
    width: 100%;
    display: flex;
    justify-content: center;

  }

  .card-grid {
    justify-content: center;
  }

  .uxer-grid {
    justify-content: center;
  }

}

.chat-left-bar {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 2;

  .chat-left-bar-messages {
    overflow-y: scroll;
    height: calc(100% - 105.8px);
  }

}

// .max-w-460{
//   max-width: 460px !important;
// }
.message-cont {
  max-width: 560px;
  position: relative;
  height: 100%;
  overflow: hidden;

  .message-header-cont {
    width: 100%;
    padding: 23px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-bottom: 1px solid #e7e7f5;
    transition: 0.2s transform;
    position: absolute;


    .message-header-icon {
      width: 28px;
      color: $Grey-04;
      stroke-width: 1.4px;
      transition: 0.1s;

      &:hover {
        color: $Orange;
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    .message-header-icon-cont {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;

    }

    .message-header-icon-close {
      display: none;
    }
  }

  .message-main {
    overflow-y: scroll;
    padding: 0;
    height: calc(100% - 186.8px);
    background-color: #fff;
    display: flex;
    flex-direction: column-reverse;
    margin-top: 105.6px;

    &::-webkit-scrollbar {}
  }

}

.banner-box {
  height: 100%;
  display: flex;
  flex-direction: column-reverse;

}

@media screen and (max-width: 960px) {
  .filter-items .filter-items-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-bottom: 32px;
    max-width: 400px;
  }

  .filter-items .filter-items-body .filter-top-inputs {
    gap: 12px;
  }

  .settings {
    max-width: 460px;
    width: 100%;
    // padding: 24px 0;

    // max-width: 100%;
    .settings-input-cont {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      padding: 0px 24px;
    }

    .settings-title {
      font-size: 28px;
    }
  }

  .settings-cont {
    max-width: 100%;
    background-color: white;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

  }

  .user-form-footer {
    max-width: 100%;
  }

  .message-main {
    height: calc(100% - 195.8px) !important;
  }

  .message-header-cont {
    transform: translateX(0px) !important;
    display: flex !important;

  }

  .message-cont {
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 123.8px);

    .message-main {
      height: calc(100% - 89.8px) !important;
      margin-top: 0px !important;
    }

    .message-input-cont {
      max-width: 100%;
      width: 100%;
    }

    .message-header-avatar {
      display: none;
    }

    .message-header-cont {
      // border-bottom: 5px solid #FF6A56;
      padding: 16px;
      transform: translateY(-80px);
      display: none;


      .message-header-icon-cont {
        width: 100%;
        gap: 16px;
      }

      .message-header-icon-close {
        display: block;
        position: absolute;
        stroke-width: 1.8px;
        color: #FF6A56;
        width: 40px;
        height: 40px;
        right: 15px;
        cursor: pointer;
      }

      .message-header-icon {
        width: 35px !important;
        height: 35px;
      }
    }
  }

  .settings-edit-user-from {
    width: 100% !important;
    max-width: 100% !important;
  }

  .experience-input {
    input::placeholder {
      bottom: 0px;
    }
  }

  .user-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .user-form-footer {
    // max-width: 100%;
    width: 100%;
  }

  .settings .settings-title-cont {
    padding: 36px 40px 5px;
    width: 100%;
    font-size: 24px !important;
    // height: 60px;
  }

  .settings-leftbar-link {
    padding: 20px 16px;

    .settings-leftbar-link-icon {
      width: 32px;
      color: #ABACCA;
      stroke-width: 1.4px;
    }
  }

  .user-form-footer {
    padding: 32px;
  }

  .filter-items .filter-items-category {
    cursor: auto;
    color: var(--primary-color);
    padding: 0px 0;
    gap: 0px;
    flex-direction: row;

    svg {
      width: 64px;
      height: 64px;
    }
  }

}


.verify-cont{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.verify-button {
  width: 320px;
}
@media screen and (max-width: 1050px) {
  .message-header-icon-cont {
    width: 100px;
    gap: 5px;
  }
}
.settings-email-cont{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.delete-account-button{
  width: 100%;
}
.delete-popup-button-popup{
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.logout_btn_box{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0 0;
  font-weight: 400;
}
//  ------------------------------------------------------------------

  @media screen and (max-width: 960px) {
  .user-form .user-form-body {
    padding: 8px 24px 24px;
    max-width: 460px;
  }
  .settings-title-cont {
    padding: 36px 24px 5px;
    width: 100%;
    max-width: 460px;
}
  .user-form-body-buttons {
  flex-direction: column-reverse;

  }
  }
  .profile-logout-button{
    background-color: #ffffff;
    color: $Grey-04;
    width: 112px;
    height: 36px;
    border: 2px solid;
    font-size: 16px;
    gap: 0;
    padding: 8px 8px 8px 10px;

    .button-right-icon{
      width: 24px;
      height: 24px;
    }
      
      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-weight: 500;
        font-size: 13px;
      }
      
        &:hover {
          color: #fff;
        }
  }