
.round-profile-image {
  display: flex;
  align-items: center;
  border-radius: 50%;
  gap: 8px;
  overflow: hidden;
  color: $White;
  transition: scale .3s ease-in-out;
  width: 44px !important;
  height: 44px !important;
  flex-shrink: 0;
  justify-content: center;

  &.round-profile-image-error {
    border: 2px solid $Red;
  }

  img {
    height: 100%;
    width: 100%;
    // background-image: url('/front/src/assets/icons/Loading_ani.svg');
    // background-size: cover;
    // background-repeat: no-repeat;
  }

  svg {
    height: 88px;
    width: 88px;
    border-radius: 100px;
  }
}

.py-2-5 {
  padding: 10px 0px !important;
}

.grey-border {
  border: 2px solid #DEDEEB;
}

.layout-profile-image {
  cursor: pointer;
  background-color: $Grey_00;
  color: var(--primary-color);

  svg {
    height: 40px;
    width: 40px;
    border-radius: 100px;
  }

  &:hover {
    transform: scale(1.05);
    background-color: var(--primary-color);
    transition: all 0.25s ease-in-out;
    color: $White;
  }
}


.default-item-photo {
  background-color: $Grey_01;
}

.item-card-logo {
  border-radius: 0%;
  height: 88px !important;
  width: 132px !important;
}

.language-switch {
  cursor: pointer;
  display: flex;
  width: 72px;
  min-height: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border-radius: 40px;
  text-align: center;
  font-family: 'M PLUS 1p';
  font-size: 13.5px;
  font-style: normal;
  font-weight: 500;
  flex-shrink: 0;
  border: 2px solid $Dark;
  color: $Dark !important;

  &[data-lang=jp] {
    font-family: Outfit, sans-serif;
    font-size: 14px;
    // max-height: 32px;

  }


  &:hover {
    background: $Dark;
    color: $White !important;
    transition: all .2s ease-in-out;

  }

}

.navigation-button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  align-self: stretch;
  color: $Dark;

  span {
    text-align: center;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-transform: uppercase;
  }

  svg {
    width: 44px;
    height: 44px;
  }

  &.navigation-selected {
    color: $White;
  }

  &:hover {
    color: $White;
    transition: all .2s ease-in-out;

  }

  &.bottom-navigation {
    flex-direction: column-reverse;

    span {
      font-size: 11px;
      font-weight: 700;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &.bottom-isolate {
    margin-left: 10px;
  }
}

.home-icon {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-family: Outfit;
  color: $Dark;
  transition: scale .2s ease-in-out;

  .uud {
    width: 54px;
    height: 60px;
  }

  .logoType {
    width: 60px;
  }

  .betaIcon {
    width: 52px;
    color: $White;
  }

  &:hover {
    color: $White;
    transition: all .2s ease-in-out;

  }
}

.input-error {
  border-color: $Red !important;
  svg{
    color: $Red !important;
  }
}

.input-error-small {
  border: 1.7px solid $Red !important;

  svg {
    color: $Red !important;
  }
}

.uxer-counter-box {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 32px;
  padding: 0 12px;
}

.uxer-counter-icon {
  // color: var(--primary-color);
  color: $Grey-04;
  width: 32px;
}

.uxer-counter-text {
  color: $Text_color;
  font-family: Outfit;
  font-size: 16px;
}

.uxer-counter-number {
  color: var(--primary-color);
  font-family: Outfit;
  font-size: 19px;
}

.exper-box {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 4px 13px;
  color: $Grey-06;
  font-family: Outfit;
  font-size: 17px;
  font-weight: 300;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 400;
    font-size: 15px;
  }

  .exper-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 18px;
    background-color: var(--primary-color);
    font-size: 13px;
    color: $White;
    border-radius: 12px;
    font-weight: 500;

  }

}

.popup-text {
  font-family: Outfit;
  font-size: 17px;
  line-height: 130%;
  padding: 0 16px;
  color: $Dark;
}

.popup-btn-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.text-yellow-default {
  color: $Yellow;
}

.outfit {
  font-family: 'outfit';
  color: $Text_color;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
  }

}

.text-gray-default {
  color: $Text_color;
}

.bg-yellow-default {
  background-color: $Yellow;
}

.signup_button {
  border: 2px solid $Orange !important;
  background-color: #fff !important;
  color: $Orange !important;
  margin-bottom: 8px !important;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
    line-height: 130%;
  }

  &:hover {
    background-color: $Text_color !important;
    color: $White !important;
    border: none !important;
  }
}

.input {
  display: flex;
  padding: 12px 12px 12px 14px;
  align-items: center;
  gap: 8px;
  border-radius: 58px;
  border: 1.5px solid $Grey_04;
  background: $White;
  width: 100%;
  height: 48px;
  color: $Blue;

  &:disabled {
    background-color: rebeccapurple;
  }

  .input-left-icon {
    height: 26px;
    width: 26px;
    color: $Grey_04;
    stroke-width: 1.5px;
    flex-shrink: 0;
  }

  .input-right-icon {
    height: 26px;
    width: 26px;
    color: $Grey_04;
    stroke-width: 1.6px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Outfit;

    &.input-right-icon-clickable:hover {
      color: $Orange;
      cursor: pointer;
    }
  }

  .input-body {
    border: none;
    width: 100%;
    color: $Text_color ;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    background-color: #ffffff00;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      color: $Dark;
    }

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: $Grey_05;
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 100% !important;

      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 130%;
      }
    }
  }
  .search_clear_number_box{
    display: flex;
    span{
      font-family: Outfit;
      font-size: 16px;
      color: var(--primary-color);
      padding-right: 4px;
    }
  }

  &.input-error {
    border-color: $Red;
    svg{
      color: $Red;
    }
  }

  &.input-disable {

    background: $Grey-00;
    border-color: $Grey-01;

    .input-left-icon {
      color: $Grey-04;
    }

  }

  &:focus-within {
    border-color: $Blue;

    .input-primary-color {
      color: var(--primary-color) !important;
    }

    .input-left-icon {
      color: $Blue;
    }

  }

  .request-dot {
    width: 10px;
    height: 10px;
    margin-left: 20px;
    ;
    color: $Orange;
    border-radius: 50%;
  }
}

input:disabled {
  background: none;
}

.input.search-input {
  &:focus-within {
    border-color: var(--primary-color);
  }
}



.tag {
  cursor: pointer;
  display: flex;
  gap: 2px;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 40px;
  background: $Grey-00;
  transition: all .2s ease-in-out;
  height: 34px;
  width: max-content;
  // width: fit-content;
  color: $Text_color;

  .tag-left-icon {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    stroke-width: 1.8px;

    svg {
      height: 18px;
      width: 22px;
    }
  }

  .tag-title {
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-size: 15px;
      font-weight: 500;
      line-height: 100%;
    }

  }

  &:hover,
  &.tag-selected {
    background-color: var(--primary-color);
    color: $White;

  }

  .tag-right-icon {
    flex-shrink: 0;
    margin: 0 4px 0 8px;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    // min-width: 30px;
    text-align: right;

    svg {
      height: 20px;
      width: 20px;
    }
  }

}

.checkbox {
  cursor: pointer;
  display: flex;
  padding: 0px 8px;
  align-items: center;
  gap: 6px;
  // width: 160px;
  color: $Text_color;


  .check-box {
    display: flex;
    width: 26px;
    height: 26px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    border: 2px solid $Grey_03;
    stroke-width: 3px;
    color: $Grey-03;


    svg {
      width: 14px;
      height: 9px;
      flex-shrink: 0;
    }

    &.checked {
      background-color: var(--primary-color);
      border: 1.6px solid var(--primary-color);
      color: $White !important;
      transition: all .3s ease-in-out;
    }
  }

  .checkbox-text {
    width: 100%;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: left;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 15px;
      line-height: 100%;
    }

  }

  .checkbox-right-icon {
    color: var(--primary-color);
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    // stroke-width: 1.5px;
  }

  &:hover {
    color: $Blue;

    .check-box {
      color: $Grey_02;
    }

  }

}

.radiobox {
  cursor: pointer;
  display: flex;
  padding: 0px 5px;
  align-items: center;
  gap: 4px;
  color: $Text_color;
  transition: all 0.2s ease-in-out;

  .radio-box {
    display: flex;
    width: 26px;
    height: 26px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 50%;
    border: 2px solid $Grey_03;
    stroke-width: 3px;
    color: $Grey-03;
    flex-shrink: 0;

    svg {
      width: 14px;
      height: 9px;
      flex-shrink: 0;
    }

    &.checked {
      background-color: var(--primary-color);
      border: 1.6px solid var(--primary-color);
      color: $White;
      transition: all .3s ease-in-out;

    }
  }

  .radiobox-text {
    width: 100%;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: left;
  }

  .radiobox-right-icon {
    color: $Blue;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    stroke-width: 1.5px;
  }

  &:hover {
    color: var(--primary-color);

    .radio-box {
      color: $Grey_02;
    }
  }

}

.button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 14px 12px 20px;
  border: none;
  border-radius: 32px;
  background-color: $Orange;
  color: $White;
  height: 48px;
  gap: 15px;
  width: 100%;
  text-align: left;
  font-family: Outfit;
  font-size: 17px;

  &[data-lang=jp] {
    // font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
    line-height: 100%;
  }

  .button-left-icon,
  .button-right-icon {
    height: 27px;
    width: 27px;
    flex-shrink: 0;
    stroke-width: 1.6px;
    // display: flex;
    align-items: center;
  }

  span {
    width: 100%;
  }

  &:hover {
    background-color: $Text_color;
  }
}

.textarea {
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 0 0 12px;
  gap: 4px;
  align-self: stretch;
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 16px;
  border: 1.6px solid $Grey_04;
  background: $White;
  overflow: hidden;

  &.textarea-error {
    border-color: $Red;
        .textarea-left-icon,
          .textarea-right-icon {
            color: $Red !important;
          }
  }

  .textarea-header {
    color: #bbbdd6;
    display: flex;
    font-family: Outfit;
    justify-content: space-between;
    margin: 1.6px;
    gap: 6px;
    padding: 0 12px 0 0;

    .textarea-left-icon,
    .textarea-right-icon {
      height: 26px;
      width: 26px;
      color: #CCCEDE;
      stroke-width: 1.6px;
      flex-shrink: 0;
      text-align: right;
    }

    .textarea-title {
      width: 100%;

      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-weight: 500;
        font-size: 15px !important;
        line-height: 100%;
        display: flex;
        align-items: center;
      }

    }
  }

  .text-area {
    border: none;
    height: 100%;
    resize: none;
    font-family: Outfit;
    font-size: 17px;
    color: $Text_color;
    padding: 0 8px 0 0;
    line-height: 120%;


    &:focus {
      outline: none;
    }
  }

  &:focus-within {
    border-color: $Blue;

    .textarea-left-icon {
      color: $Blue;
    }
  }
}


// .select-container {
//   position: relative;
//   width: 100%;
//   display: flex;
//   flex-direction: column;

//   .select-display {
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     padding: 12px;
//     border-radius: 58px;
//     border: 1.5px solid $Grey_04;
//     background: $White;
//     font-family: Outfit;
//     font-size: 17px;
//     cursor: pointer;
//     color: $Grey_05;
//     gap: 4px;

//     .select-left-icon {
//       height: 26px;
//       width: 26px;
//       color: $Grey_04;
//       stroke-width: 1.6px;
//       flex-shrink: 0;
//     }

//     span {
//       width: 100%;
//       text-align: left;
//     }

//     .select-icon {
//       margin-left: 10px;
//       width: 24px;
//       height: 24px;
//       flex-shrink: 0;
//     }
//   }

//   .custom-dropdown {
//     overflow: hidden;
//     position: absolute;
//     top: 105%;
//     left: 0;
//     width: 100%;
//     border: 1.5px solid $Grey_04;
//     border-radius: 15px;
//     background: $White;
//     z-index: 1;
//     max-height: 200px;
//     overflow: auto;

//     .dropdown-option {
//       padding: 10px 15px;
//       cursor: pointer;
//       border: 0.2px solid $Grey-01;
//       color:  $Text_color;
//       font-family: Outfit;
//       font-size: 17px;

//       &:hover, &:focus {
//         background-color: $Grey-06;
//         color: $White;
//       }
//     }
//   }
//   &.select-container-error {
//     .select-display {
//       border-color: $Red;
//     }
//   }
// }

.select-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;

  .disable-select {
    background-color: #F5F6FA !important;
  }

  &.is-open {
    .select-display {
      border-color: $Blue;
    }

    .select-left-icon {
      color: $Blue !important;
    }

    .select-right-icon:not(.anime) {
      stroke-width: 2px;
      color: $Blue;
    }
  }

  .select-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 12px 14px;
    border-radius: 58px;
    border: 1.5px solid $Grey_04;
    background: $White;
    color: $Text_color;
    font-family: Outfit;
    font-size: 17px;
    cursor: pointer;
    color: $Grey_05;
    height: 48px;
    stroke-width: 1.6px;
    gap: 4PX;
    background-color: $White !important;

    .select-show {
      display: flex;
      align-items: center;
      gap: 4px;

      img {
        height: 26px;
        width: 26px;
        object-fit: cover;
        border-radius: 50%;
        border: 1.4px solid $Grey_02;
      }
    }

    .select-placeholder {
      display: flex;
      align-items: center;
      gap: 4px;

      svg {
        height: 26px;
        width: 26px;
        color: $Grey_04;
      }

      input {
        border: none;

        &:focus {
          outline: none;
        }
      }
    }

    .select-left-icon {
      height: 26px;
      width: 26px;
      color: $Grey_04;
      stroke-width: 1.6px;
      flex-shrink: 0;
    }

    span {
      width: 100%;
      text-align: left;

      &.selected {
        color: $Text_color;
        line-height: 92%;
      }
    }

    .select-right-icon {
      margin-left: 10px;
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      stroke-width: 2;
      color: $Grey-04;
    }

    .select-right-icon {
      margin-left: 10px;
      width: 24px;
      height: 24px;

      &.anime {
        transform: rotateZ(-30deg);
        transition: all ease-in-out .3s;

        &:hover {
          transform: rotateZ(0deg);
          color: var(--primary-color);
        }
      }
    }


  }

  .custom-dropdown {
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    border: 1.5px solid $Blue;
    border-radius: 20px;
    background: $White;
    z-index: 1;
    max-height: 282px;
    overflow: hidden;
    z-index: 10;

    .options {
      overflow: auto;
      max-height: 280px;

      .dropdown-option {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 10px 15px;
        cursor: pointer;
        border: 0.2px solid $Grey-01;
        color: $Text_color;
        font-family: Outfit;
        font-size: 17px;

        img {
          width: 26px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid $Grey-03;
        }

        &:hover,
        &:focus {
          background-color: $Grey-00;
        }
      }
    }
  }

  &.select-container-error {
    .select-display {
      border-color: $Red;
    }
    svg{
      color: $Red
    ;
    }
  }

}

.switch_box {
  display: flex;

  // color: $Text_color;
  // font-family: Outfit;
  // font-weight: 400;
  // font-size: 17px;
  // margin: 8px;
  // line-height: 110%;
  // width: 100%;
  gap: 12px;

  .checkbox-group {
    width: auto;
    // height: 26px;
    flex-shrink: 0;

    label {
      height: 100%;
      width: 100%;
    }
  }

  .title-secondary {
    color: $Grey-06;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;
  }
}


.text-link {
  color: $Orange;
  text-decoration: none;
  // margin-left: 2px;
  line-height: 110%;
  width: max-content;

  &:hover {
    color: $Text_color_Orange;
    cursor: pointer;
    text-decoration: underline;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  visibility: hidden;
  display: none;

  &:checked~.check {
    background-color: $Green;

    &::before {
      transform: translateX(14px);
      border-color: $Green;
    }
  }
}

.checkbox-error {
  background-color: $Red !important;

  &::before {
    border: 2px solid $Red !important;
  }
}

.check {
  position: relative;
  display: block;
  width: 36px;
  height: 22px;
  background-color: $Grey_04;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: ease-in 0.1s;

  &::before {
    content: '';
    position: absolute;
    margin: 0px;
    background-color: $White;
    border: 2px solid $Grey_04;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    transition: 0.2s;
  }
}

.dropdown-select {
  position: relative;
  font-weight: 400;
  color: $Text_color;
  padding-bottom: 0;
  width: 100%;
  height: 48px;
  font-family: 'Outfit';

  &:hover {
    cursor: pointer !important;

  }


  
  .left-icon {
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
  }

  .clean-icon {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    transform: rotateZ(0deg);
    transform-origin: 14px;
    height: 27px;
    width: 27px;
    color: $Grey_04;
    stroke-width: 1.5px;
    margin-right: 3px;

    &:hover {
      transform: rotateZ(30deg);
      color: var(--primary-color);
    }
  }

  &.dropdown-focused {
    .left-icon {
      color: $Blue;
    }
                    .dropdown-select-inner__single-value {
                      color: $Grey-04;
                    }
    .left-icon-primary {
      color: var(--primary-color) !important;
    }

    .dropdown-indicator-primary {
      color: var(--primary-color) !important;
    }

    &.primary-yellow {
      .dropdown-select-inner {
        border-color: $Orange;
      }
    }

  }

  .dropdown-select-inner-primary {
    position: relative;
    height: 100%;
    padding-left: 40px;
    border: 1.7px solid $Grey_04;
    border-radius: 48px;

    &__control {
      border-radius: 0 48px 48px 0;
      background-color: transparent;
      padding-left: 0;
      border: none;
      height: 100%;
      outline: none;

      &:hover,
      &:focus,
      &.dropdown-select-inner-primary__control--menu-is-open {
        box-shadow: none !important;


      }

      &.dropdown-select-inner-primary__control--menu-is-open {
        outline: none;

      }

      &:hover,
      &:focus,
      &.dropdown-select-inner-primary__control--is-focused {
        box-shadow: none !important;

        &~& {
          border-color: red !important;

        }
      }

      &.dropdown-select-inner-primary__control--is-focused {
        outline: none;

        &~& {
          border-color: red !important;
        }
      }

      &.dropdown-select-inner-primary__control {
        cursor: pointer;
      }
    }

    &__value-container {
      padding-left: 2px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $Grey_05;

    }

    &__menu {
      position: absolute;
      overflow: hidden;
      margin-top: 8px;
      right: 0;
      z-index: 30;
      outline: 1.7px solid var(--primary-color);
      border-radius: 20px;

      &-list {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__option {
      padding: 8px 12px;
      border-bottom: 1.7px solid $Grey_02;

      &:hover,
      &:focus,
      &.dropdown-select-inner-primary__option--is-focused {
        background-color: $Grey_00 !important;
        color: inherit;
      }

      &.dropdown-select-inner-primary__option--is-focused {
        outline: none;

      }

      &:hover,
      &:focus,
      &.dropdown-select-inner-primary__option--is-selected {
        background-color: $Grey_01 !important;
        color: inherit;
      }

      &.dropdown-select-inner-primary__option--is-selected {
        outline: none;

      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__option-container {
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 4px 0;
      gap: 12px;

      .dropdown-select-inner-primary__flag-container {
        position: relative;
        border-radius: 999px;
        height: 28px;
        width: 28px;
        // border: 1px solid $Grey_03;
        overflow: hidden;
        z-index: 100;

        .dropdown-select-inner-primary__flag {
          position: relative;
          z-index: 100;
          height: 100%;
          width: 100%;
          object-fit: cover;


        }
      }
    }
  }

  .dropdown-select-inner {
    position: relative;
    height: 100%;
    padding-left: 42px;
    border: 1.7px solid $Grey_04;
    border-radius: 48px;

    &__control {
      border-radius: 0 48px 48px 0;
      background-color: transparent;
      padding-left: 0;
      border: none;
      height: 100%;
      outline: none;

      &:hover,
      &:focus,
      &.dropdown-select-inner__control--menu-is-open {
        box-shadow: none !important;


      }

      &.dropdown-select-inner__control--menu-is-open {
        outline: none;

      }

      &:hover,
      &:focus,
      &.dropdown-select-inner__control--is-focused {
        box-shadow: none !important;

        &~& {
          border-color: red !important;

        }
      }

      &.dropdown-select-inner__control--is-focused {
        outline: none;

        &~& {
          border-color: red !important;
        }
      }

      &.dropdown-select-inner__control {
        cursor: pointer;
      }
    }

    &__value-container {
      padding-left: 2px;
    }

    &__indicator-separator {
      display: none;
    }

    &__placeholder {
      color: $Grey_05;

    }

    &__menu {
      position: absolute;
      overflow: hidden;
      margin-top: 8px;
      right: 0;
      z-index: 30;
      outline: 1.7px solid $Blue;
      border-radius: 20px;

      &-list {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &__option {
      padding: 8px 12px;
      border-bottom: 1.7px solid $Grey_02;

      &:hover,
      &:focus,
      &.dropdown-select-inner__option--is-focused {
        background-color: $Grey_00 !important;
        color: inherit;
      }

      &.dropdown-select-inner__option--is-focused {
        outline: none;

      }

      &:hover,
      &:focus,
      &.dropdown-select-inner__option--is-selected {
        background-color: $Grey_01 !important;
        color: inherit;
      }

      &.dropdown-select-inner__option--is-selected {
        outline: none;

      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__option-container {
      display: flex;
      align-items: center;
      justify-content: left;
      margin: 4px 0;
      gap: 12px;

      .dropdown-select-inner__flag-container {
        position: relative;
        border-radius: 999px;
        height: 28px;
        width: 28px;
        // border: 1px solid $Grey_03;
        overflow: hidden;
        z-index: 100;

        .dropdown-select-inner__flag {
          position: relative;
          z-index: 100;
          height: 100%;
          width: 100%;
          object-fit: cover;


        }
      }
    }
  }
.error-input-icon{
  color: $Red !important;
}
  .css-1xc3v61-indicatorContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-transition: color 150ms;
    transition: color 150ms;
    color: $Grey-04;
    padding: 6px 8px;
    box-sizing: border-box;
    width: 47px;
    height: 45px;
    align-items: center;
    justify-content: center;
  }

  .css-15lsz6c-indicatorContainer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    -webkit-transition: color 150ms;
    transition: color 150ms;
    color: $Blue;
    padding: 6px 8px;
    box-sizing: border-box;
    width: 47px;
    height: 45px;
    align-items: center;
    justify-content: center;

  }

  .left-icon {
    stroke-width: 1.5px;
    color: #cccede;
    height: 25px;
    margin-left: 17px;
    position: absolute;
    width: 25px;
  }
}

.error-select {
  border: 1.7px solid $Red !important;
}

.flag-container {
  border-radius: 999px;
  // border: 1.5px solid $Grey_03;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  margin-left: 12px;
  z-index: 2;

  .flag {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.dropdown-focused {
  .dropdown-select-inner-primary {
    border: 1.6px solid var(--primary-color) !important;
  }

  .dropdown-select-inner {
    border: 1.6px solid $Blue ;
  }

  .dropdown-focused-inner {
    border: 1px solid $Orange !important;
  }
}

// ------- Remove Autofill BG Color -----------------------------

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
  color: $Text_color !important;
}

.page-title {
  display: flex;
  // justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 30px 12px;
  border-bottom: 1px solid #e7e7f5;
  -webkit-user-select: none;
  gap: 8px;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;

  span {
    text-align: center;
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    // text-transform: uppercase;
    color: #FF6956;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 28px !important;
    }

  }

  .icon-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F6FA;
    border-radius: 50%;
    gap: 1px;
    padding: 10px;
    position: relative;
    cursor: pointer;
    color: #FF6956;

    svg {
      width: 25px;
      height: 25px;
      stroke-width: 1.8px;
    }

    &:hover {
      background-color: $Grey-02;
      // color: $White;
    }
  }

  .icon-number {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF6956;
    border-radius: 50%;
    height: 17px;
    width: 17px;
    color: #fff;
    font-family: Outfit;
    font-size: 12px;
    position: absolute;
    left: -7px;
  }
}

//  ----------------------- MEDIA QUERY --------------------

@media screen and (max-width: 960px) {

  .tag {
    padding: 12px;
    height: 46px;

    .tag-title {
      font-size: 17px;
    }

  }

  .radiobox {
    gap: 6px;
    width: 148px;
    padding: 4px 0px;

    .radio-box {
      width: 34px;
      height: 34px;
      border: 2px solid #DEDEEB;

      svg {
        width: 17px;
        height: 12px;
        flex-shrink: 0;
      }
    }

    .radiobox-text {
      font-size: 17px;
    }

    .radiobox-right-icon {
      width: 34px;
      height: 34px;
    }

  }

  .checkbox .checkbox-text[data-lang=jp] {
    font-size: 16px;
  }

  .tag .tag-title[data-lang=jp] {
    font-size: 17px;
  }

  .checkbox {
    gap: 6px;
    // width: 170px;
    padding: 4px 0px;

    .long {
      width: 200px !important;
      background-color: aquamarine;
    }

    .check-box {
      width: 34px;
      height: 34px;
      border: 2px solid #DEDEEB;

      svg {
        width: 17px;
        height: 12px;
        flex-shrink: 0;
      }

    }

    .checkbox-text {
      font-size: 17px;
    }

    .checkbox-right-icon {
      width: 34px;
      height: 34px;
    }

  }

  .input {
    height: 52px;
  }

  .dropdown-select {
    height: 58px;
  }

  .select-container .select-display {
    height: 58px;
  }

  .select-container .custom-dropdown .options .dropdown-option {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 16px 15px;
    cursor: pointer;
    border: 0.2px solid #F0F0F9;
    color: $Text_color;
    font-family: Outfit;
    font-size: 17px;
  }

  .select-container .select-display .select-right-icon {
    margin-left: 10px;
    width: 28px;
    height: 28px;
    color: $Grey-04;
  }

  .button {
    height: 54px !important;

    .button-left-icon,
    .button-right-icon {
      height: 28px;
      width: 28px;
    }
  }

  .input {

    .input-right-icon,
    .input-left-icon {
      height: 28px;
      width: 28px;
      color: #CCCEDE;
    }
  }

  .check {
    position: relative;
    display: block;
    width: 44px;
    height: 30px;
    background-color: $Grey_04;
    cursor: pointer;
    border-radius: 20px;
    overflow: hidden;
    transition: ease-in 0.1s;

    &::before {
      position: absolute;
      margin: Opx;
      background-color: $White;
      border: 4px solid $Grey_04;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      transition: 0.2s;
    }
  }

  .switch_box {
    margin: 8px;
    display: flex;
    line-height: 110%;
    width: 100%;
    margin: 12px 0 0 0;
    align-items: center;
    padding: 8px 4px !important;

    .checkbox-group {
      width: 74px;
      flex-shrink: 0;
    }


  }

  .uxer-counter-box {
    padding: 0 8px;
  }

  .uxer-counter-icon {
    width: 38px;
  }

  .uxer-counter-text {
    font-size: 18px;
  }

  .language-switch {
    cursor: pointer;
    display: flex;
    width: 88px;
    min-height: 46px;
    margin: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 0;
    border-radius: 40px;
    text-align: center;
    font-family: "M PLUS 1p";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    flex-shrink: 0;
    border: 2px solid #ffffff;
    color: #ffffff !important;

    &[data-lang=jp] {
      font-family: Outfit, sans-serif;
      font-size: 16px !important;
  }

  &:hover {
    background: $Dark;
    color: $White !important;
    border:  2px solid $Dark;
    transition: all .2s ease-in-out;
  }

}

}

.eyeIcon {
  width: 25px;
  height: 25px;
}

//----------------------------------------------------------------
.clear-icon {
  width: 25px;
  height: 25px;
}

.input-numbers {
  display: flex;
  align-items: center;
  gap: 0px;
  font-family: Outfit;
  color: $Grey-04;
  font-size: 17px;
padding-right: 6px;
  span {
    padding: 0 4px;
  }
}
.input-disable-placeholder{
  color: $Grey-06 !important;
}
.input-input-cont{
  display: flex;
  gap:3px;
  width: 100%;

}
.input-input-pre-text {
color: $Grey-04;
}