@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";


.card-specs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .card-specs-platform-price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    svg {
      height: 26px;
      width: 26px;
      stroke-width: 1.4px;
      color: $Grey_04;
    }
  }

  .card-specs-tags {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: start;
    gap: 5px;
    flex-wrap: wrap;
    overflow: hidden;
    height: 26px;

    span {
      display: flex;
      padding: 5px 9px 6px;
      align-items: center;
      gap: 4px;
      border-radius: 26px;
      background: $Grey_00;
      color: $Text_color;
      font-family: Outfit;
      font-size: 13.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 16.8px */
    }
  }
}

.app-card-specs {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .card-specs-apps-platform {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .apps_platform_icon{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 32px;
      height: 48px;
      width: 48px;
      background-color: $Grey_00;
      color: $Grey_05;
      cursor: pointer;

      &:hover {
        color: $White;
        background-color: var(--primary-color);
      }

    }

    svg {
      height: 30px;
      width: 30px;
      stroke-width: 1.4px;
    }
  }

  .card-specs-platform-price {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1px;

    svg {
      height: 26px;
      width: 26px;
      stroke-width: 1.4px;
      color: $Grey_04;
      
    }

    .card-specs-tags {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: start;
      gap: 5px;
      flex-wrap: wrap;
      overflow: hidden;
      height: 26px;
  
      span {
        display: flex;
        padding: 5px 9px 6px;
        align-items: center;
        gap: 4px;
        border-radius: 26px;
        background: $Grey_00;
        color: $Text_color;
        font-family: Outfit;
        font-size: 13.5px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 16.8px */
      }
    }
  }
}

.the-end-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: $Grey_04;
  padding: 24px;
  width: 100%;

  span {
    color: $Grey_04;
    text-align: center;
    font-family: Outfit;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
  }

  svg {
    width: 70px;
  }
}

.tags-filter {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 10px 8px;
  align-self: stretch;
  flex-wrap: wrap;

  .tags-filter-top {
    display: flex;
    width: 100%;
    gap: 10px 7px;
    flex-direction: row;

    &[data-lang=jp] {
      flex-direction: column;
    }

  }
}

.checkbox-group,
.radiobox-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px 2px;
  // padding: 0 6px;

  &.check-w-auto>div {
    width: auto;
  }
}

.load-more-items {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--primary-color);

  .load-more-title {
    text-align: center;
    font-family: Outfit;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 28.8px */
  }

  .load-more-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    div {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background-color: $Grey_03;

      &:nth-child(1) {
        animation: loadingAnimation 1500ms 0s infinite;
      }

      &:nth-child(2) {
        animation: loadingAnimation 1500ms 500ms infinite;
      }

      &:nth-child(3) {
        animation: loadingAnimation 1500ms 1s infinite;
      }
    }
  }

  .load-more-logo {
    width: 70px;
  }
}

@keyframes loadingAnimation {

  0% {
    background-color: $Grey-03;
  }

  30% {
    background-color: $Grey-03;
  }

  50% {
    background-color: var(--primary-color);
  }

  70% {
    background-color: var(--primary-color);
  }

  100% {
    background-color: $Grey_03;
  }
}

.upload-avatar-window {
  width: 320px !important;
  gap: 16px !important;
}

.upload-avatar-title {
  font-family: 'outfit'


}

.large-popup-container {
  position: fixed;
  display: flex;
  align-items: baseline;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: $Text_color, $alpha: 0.9);
  z-index: 99999;
  overflow-y: auto;
  padding: 50px 0;

  .popup-card {
    position: relative;
    display: flex;
    padding: 44px 72px 68px;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 100%;
    max-width: 820px;
    margin: 32px;
    border-radius: 24px;
    background: $White;

    .close-btn {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  button {
    background-color: var(--primary-color);
  }
}

.experience-input {
  max-width: 22%;
  display: flex;
  align-items: center;
  gap: 3px;

  .input-body {
    border: none;
    width: 100%;
    color: #513C6A !important;
    font-family: Outfit;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    background-color: rgba(255, 255, 255, 0);
    text-align: center;
  }


}

.medium-popup-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: $Text_color, $alpha: 0.9);
  z-index: 99999;
  overflow-y: auto;
  padding: 32px 0;

  .popup-card {
    position: relative;
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    width: 560px;
    max-width: 100%;
    margin: 12px;
    border-radius: 24px;
    background: $White;

    .close-btn {
      display: block;
      position: absolute;
      top: 12px;
      right: 12px;
    }
  }

  button {
    background-color: var(--primary-color);
  }
}


.uxer_terms_card {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 16px;
  align-self: stretch;

  .uxer_terms_title {
    display: flex;
    width: 100%;
    padding: 8px 0;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: var(--primary-color);
    font-family: Outfit;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 32px */
  }

  .uxer_terms_subtitle {
    width: 100%;
    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }

  .uxer_terms_content {
    color: $Text_color;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;

    span {
      font-size: 20px;
      font-weight: 500;
      color: $Orange;
    }

    .uxer_terms_content_primary {
      color: var(--primary-color);
      font-weight: 400;
    }

    ul {
      span {
        font-size: 17px;
        font-weight: 500;
        color: $Dark;
      }
    }

    li {
      list-style-type: disc;
    }

  }
}

.uxer_form_card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .uxer_form_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;

    .uxer_form_header {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      color: var(--primary-color);

      svg {
        width: 100%;
        height: 84px;
      }

      .uxer_form_title {
        display: flex;
        padding: 8px 0px;
        width: 100%;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        font-family: Outfit;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
      }

      p {
        padding: 8px;
        color: $Text_color;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 17.6px */

        .uxer_form_info {
          color: $Grey_05;
          font-family: Outfit;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%;
        }
      }
    }
  }
}

.noLogged_message_container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  width: 100%;

  .noLogged_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: white;
    padding: 40px 72px;
    border-radius: 20px;
    width: 100%;
    max-width: 540px;
    min-width: 260px;

    .noLogged-title {
      color: var(--primary-color);
      font-size: 24px;
      padding: 4px 0 8px;

      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-size: 22px;
        padding: 4px 0 10px;
      }
    }

    .noLogged_message_header {
      color: var(--primary-color);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0;

      .noLogged_message_logo {
        width: 100%;
        height: auto;
        margin-bottom: 8px;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      .noLogged_message_title {
        text-align: center;
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
    }

    .especial-text {
      color: var(--primary-color);
      // font-size: 19px;
    }

    .noLogged_message_content {
      color: var(--def-darker, $Text_color);
      text-align: center;
      font-family: Outfit;
      font-size: 17.5px;
      font-style: normal;
      font-weight: 400;
      line-height: 114%;

      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-weight: 500;
        font-size: 15.5px;
        line-height: 150%;
        margin-top: 8px;
      }
    }

    .noLogged_message_button_group {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;
      padding-top: 12px;

.noLogged_button.noLogged_button_outline{
  max-width: none;
}
      
    }
  }
}

.noLogged_button {
  background-color: var(--primary-color);
  width: 100%;
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-align: left;
  text-wrap: nowrap;
  gap: 4px;

  &[data-lang=jp] {
    // font-family: 'M PLUS 1p', sans-serif;
    font-weight: 400;
    // font-size: 15px !important;
  }

  &:hover {
    background-color: $Text_color;
  }

  &.noLogged_button_outline {
    background: none;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    // max-width: -moz-fit-content;
    // max-width: fit-content;

    // width: 124px;

    &:hover {
      background-color: $Text_color;
      border: 2px solid $Text_color;
      color: $White;
    }
  }

  &.longer {
    // flex: 2 1;
    // width: 200px;
  }
}

.no_result_found_card {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  height: calc(100dvh - 100px);
}

.no_result_found_card_part {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $Grey_05;
}

.nofavs_icon {
  color: $Grey_03;
  width: 110px;
}

.loading_card {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
  height: calc(100dvh - 100px);

  svg {
    width: 200px;
    height: 200px;
  }

}

.popup-cont {
  z-index: 3000;

}

.popup {
  width: 100%;
  display: flex;
  padding: 12px;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 32px;
  left: calc(50% - 192px);
  z-index: 9999999;

  &.popup-error {
    background-color: $Red;
    color: $White;
  }

  &.popup-success {
    background-color: $Green;
    color: $White;
  }

  &.popup-info {
    background-color: $Blue;
    color: $White;
  }

  &.popup-loading {
    background-color: $Blue;
    color: $White;
  }

  .popup-left-icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    stroke-width: 2px;
  }

  .popup-message {
    color: $White;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
  }

  .popup-close {
    cursor: pointer;
    width: 28px;
    height: 28px;
    stroke-width: 1.5;

    &:hover {
      Color: $Text_color;
    }
  }
}

.avatar_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 5px;

  &.avatar_box_error {
    .avatar {
      .round-profile-image {
        border: 1.8px solid $Red;
      }
    }
  }

  .avatar {
    position: relative;
    width: 112px !important;
    height: 112px !important;
    // margin-bottom: 12px;

    .round-profile-image {
      height: 100% !important;
      width: 100% !important;
      background-color: $Grey-00;
      border: 2.5px solid $Grey-00;

      svg {
        color: $Grey-03;
      }
    }


    .avatar-icon,
    .trash-icon {
      cursor: pointer;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 50%;
      padding: 5px;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    .avatar-icon {
      stroke-width: 1.4px;
      background-color: $White;
      border: 2px solid $Grey_02;
      border-radius: 56px;
      color: var(--primary-color);
      height: 38px;
      width: 38px;
      position: absolute;
      top: 50%;
      right: -30px;
      transform: translate(50%, -50%);

      &:hover {
        background-color: var(--primary-color);
        color: $White;
      }
    }

    .trash-icon {
      top: 50%;
      right: 0;
    }
  }

}

.container-editor {

  .editor {
    border-radius: 120px;
    border: solid 4px $Grey_01;
    width: 160px !important;
    height: 160px !important;

  }

  .MuiSlider-root {
    color: var(--primary-color);
    border-radius: 6px;

  }

  .MuiSlider-rail {
    height: 6px;
    color: $Grey_03;
    border-radius: 6px;
  }

  .MuiSlider-track {
    height: 6px;
    border-radius: 6px;
  }

  .MuiSlider-thumb {
    width: 20px;
    height: 20px;
    border: solid 2px $White;
    top: 11px;
  }

  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 6px rgba(232, 49, 13, 0.16);
  }

  .MuiSlider-thumb.MuiSlider-active {
    box-shadow: 0px 0px 0px 6px rgba(25, 60, 158, 0.16);
  }
}

.chat-avatar-small {
  display: flex;
  align-items: center;
  gap: 10px;

  .chat-avatar-info-name {
    font-size: 17px;
    font-family: Outfit;
    color: $Text_color;
    font-weight: 400;
    line-height: 1.1;
  }

  .chat-avatar-info-title {
    font-size: 16px;
    font-family: Outfit;
    color: #ABACCA;
    font-weight: 400;
    line-height: 1.1;
  }

  .chat-avatar-info-country-cont {
    display: flex;
    align-items: center;
    gap: 5px
  }

  .chat-avatar-info-country {
    color: #ABACCA;
    font-family: Outfit;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;

  }

  svg {}

  .chat-avatar-country-flag-cont {
    border-radius: 50%;
    overflow: hidden;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chat-avatar-country-flag {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.message-input-cont {
  height: 90px;
  background-color: #EFF0F8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  gap: 7px;
  max-width: 560px;


  .message-input {
    height: 60px;
    outline: none;
    border: none;
    width: 100%;
    height: 100%;
    padding: 2px 10px;
    border-radius: 6px;
    resize: none;
    font-size: 16px;
    font-family: Outfit;
    border: 1.5px solid $White;
    font-weight: 400;
    line-height: 120%;
    color: $Text_color;


    &:focus-within {
      border: 1.5px solid $Grey_03;
    }
  }

  .message-input-button {
    width: 35px;
    background-color: $Orange;
    height: 100%;
    border: none;
    border-radius: 6px;
    cursor: pointer;

    &:hover {
      background-color: $Text_color;
    }
  }

  .message-input-button-icon {
    fill: #FFFFFF;
    stroke-width: 0px;
    width: 24px;
    border: none;
  }

  .message-unblock-desc {
    color: #878BB7;
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  .message-unblock-button {
    color: #878BB7;
    leading-trim: both;
    text-edge: cap;
    /* Typo - Par - R16 */
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    border-radius: 32px;
    border: 1.4px solid #878BB7;
  }

}

//  -------------------------------- MEDIA QUERY -----------------------------------

@media screen and (max-width: 960px) {

  .tags-filter {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 12px 8px;
    align-self: stretch;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;

    .tags-filter-top {
      display: flex;
      width: 100%;
      gap: 10px 7px;
      flex-direction: row;
      justify-content: center;

      &[data-lang=jp] {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }

  }
  .radiobox-group,
  .checkbox-group {
    width: 100%;
    display: flex;
    flex-direction: initial;
    align-items: flex-start;
    align-self: stretch;
    flex-wrap: wrap;
    gap: 4px 6%;
    padding: 0 8px;
  }

  .noLogged_button
  noLogged_button_outline {
    flex: 1 1;
    width: 100%;
  }

  .large-popup-container .popup-card {
    padding: 38px 48px;
  }

  .noLogged_button {
    min-width: 100%;
    width: auto;
  }

  .noLogged_message_container .noLogged_message .noLogged_message_button_group {
    flex-direction: column;
    padding: 12px 10% 0;
  }

  
  .chat-avatar-large {
    display: none;
  }

  .avatar_box .avatar .avatar-icon {
    height: 48px !important;
    width: 48px !important;
    position: absolute;
    top: 50%;
    right: -36px;
  }

  .upload-avatar-window {
    width: 320px !important;
    gap: 24px !important;
  }

  .noLogged_message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    background-color: white;
    padding: 40px 24px !important;
    border-radius: 20px;
    width: 100%;
    max-width: 540px;
    min-width: 260px;

    .noLogged_message_header {
      .noLogged_message_logo {
        width: 100%;
        height: auto !important;
        margin-bottom: 8px;
      }
    }
  }


  .avatar_box {
    justify-content: center;
  }

}

//  --------------------------------------------------------------------------------