@import "variables";


// - - - -- - - DATE - DashBoard - - - - - - - - - - - -

.date_time_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  gap: 4px;
  text-align: center;
  font-family: Outfit;
  font-weight: 400;
  border: 1.6px solid $Grey-03;
  padding: 8px 4px 8px 10px;
  border-radius: 12px;
  color: $Grey-06;
}

.year_month_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 0px;

  .month {
    text-transform: uppercase;
    font-size: 12px;
    line-height: 100%;
    font-weight: 600;
  }

  .day_number {
    font-size: 28px;
    font-weight: 400;
    line-height: 100%;
  }
}

.day_hour_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  justify-content: center;
  gap: 0px;
  width: 40px;

  .day {
    font-size: 17px;
    line-height: 100%;
    font-weight: 500;
  }

  .hour {
    font-size: 11px;
    line-height: 100%;
    font-weight: 600;
    }

  .year {
    font-size: 11px;
    line-height: 100%;
    font-weight: 600;
  }
}

// - - - - - - - - - - - - - - - - - - - - - - -
.layout-dashboard {
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  border: 8px solid $Dark;

  .left-menu-bar {
    display: flex;
    flex-direction: column;
    padding: 32px 8px 36px 0px;
    align-items: center;
    gap: 24px;
    background-color: $Dark;
    box-shadow: 0px 1px 0px 0px rgba(76, 49, 106, 0.13);
    min-width: 116px;

    svg.logo {
      color: $Orange;
      width: 76px !important;
      height: 76px !important;
      flex-shrink: 0;
    }

    .left-bar-links {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      height: 100%;

      .left-bar-cont {
        display: flex;
        flex-direction: column;
        gap: 1px;
        align-items: center;
        color: $Orange;

        &:hover{
          cursor: pointer;
          color: $White;
        }
      }

      .left-bar-link {
        text-align: center;
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        &.selected {
          color: $White;
        }
      }

      .left-bar-hr-show {
        width: 64px;
        height: 1px;
        opacity: 0.1;
        background: $White;
      }
    }

    .left-bar-ibuttons {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex-shrink: 0;

      svg {
        cursor: pointer;
        height: 40px;
        width: 40px;
        stroke-width: 1.8px;
        color: $Orange;

        &:hover{
          color: $White;
        }
      }
    }
  }

  .left-bar-icon {
    width: 38px;
    height: 38px;
    stroke-width: 1.5px;
  }

  .dashboard-search-number {
    color: #FF6956;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .dashboard-clear-icon {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      color: $Orange;
    }
  }

  .top-menu-bar {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    background-color: $White;
    width: 100%;
    height: 98px;
    border-bottom: 1px solid $Grey-03;

    .top-bar-left-state {
      display: flex;
      align-items: center;
      gap: 6px;

      .top-bar-icon {
        color: $Orange;
        width: 48px;
        height: 48px;
        stroke-width: 1.4px;
      }

      .top-title-box {
        display: flex;
        align-items: normal;
        justify-items: center;
        gap: 6px;
        color: var(--def-orange, $Orange);
        text-align: center;
        font-family: Outfit;
        font-size: 28px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        text-transform: uppercase;

        // .state-title {
        // }
        .state-body {
          color: var(--def-dark, $Text_color);
        }
      }
    }

    .top-bar-right-state {
      display: flex;
      justify-content: flex-end;
      gap: 8px;
      width: 100%;

      .statistique-icon {
        display: flex;
        flex-direction: column;
        padding: 0 8px;
        align-items: center;
        justify-content: center;
        font-family: Outfit;
        font-size: 20px;
        font-style: normal;
        gap: 5px;
        color: $Text_color;



    

        .statistique-body {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
   
          .statistique-title {
            font-size: 14px;
            line-height: 70%;
            font-weight: 500;
            text-align: left;
            color:  $Grey-06;
          }
          svg {
            width: 28px;
            height: 28px;
            flex-shrink: 0;
            stroke-width: 1.4px;
            color:  $Blue;
  
          }
        }
        .statistique-data {
          font-family: Outfit;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          width: 100%;
          text-align: right;
        }
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    justify-items: center;
    justify-content: center;
    padding: 24px;
  }

  .loading_box{
       svg{
      width: 180px;
      color: $Text_color;
    }
  }
}

#dashboard-items {
  width: 100%;
  overflow-y: scroll;

  .dashboard-filter-items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    padding: 0 12px;
    max-width: 1068px;
    z-index: 10;


    .dashboard-button-group {
      display: flex;
      gap: 6px;
      align-items: center;
    }

    .input {
      display: flex;
      padding: 12px 12px 12px 8px;
      align-items: center;
      gap: 4px;
      border-radius: 58px;
      border: 1.5px solid #CCCEDE;
      background: #FFFFFF;
      width: 100%;
      height: 48px;
      color: #15A6F8;
  }

  //   .dropdown-select {
  //     position: relative;
  //     font-weight: 400;
  //     color: $Text_color;
  //     padding-bottom: 0;
  //     width: 100%;
  //     height: 44px;
  //     font-family: "Outfit";
  // }
    .secondary {
      margin-right: 0;
      background-color: #fff;
      color: $Orange;
      border: 1.6px solid $Orange;
      border-radius: 48px;

        &:hover {
          background-color: $Orange;
          color: $White;
        }
      
    }

    .primary {
      width: auto;
      white-space: nowrap;
      border-radius: 48px;

        &:hover {
          background-color: $Dark;
        }
    }

    .primary-green {
      width: auto;
      white-space: nowrap;
      border-radius: 48px;
      background-color: $Green;

        &:hover {
          background-color: $Dark;
        }
    }

    .dashboard-select {
      width: 200px !important;
      height: 44px;
      cursor: pointer;

      &:focus {
        border: 2px solid $Green;
      }
    }

    .dashboard-search {
      height: 44px;
      width: 200px;
    }

    .dashbaord-select-indicator {
      color: $Blue;
      font-size: 16px;
    }

    .dashboard-select-placeholder-cont {
      display: flex;
        color: $Text_color;
        justify-content: space-between;
        width: 150px;
        padding: 0 4px;
      }
      
      .dashboard-select-option-cont {
        display: flex;
        color: $Text_color;
        justify-content: space-between;
        width: 100%;
        font-family: Outfit;
        line-height: 110%;
        // gap: 6px;
        align-items: center;
      
        span {
          width: 60%;
        }
      
        .dashbaord-select-indicator {
          width: 40%;
          text-align: right;
        }

    }

    .dashboard-select-right-icon {
      padding: 0px !important;
      width: 28px;
      margin-right: 3px;
      margin-left: -7px;
      position: relative;
      top: -0.5px;
      stroke-width: 1.6px;
    }

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
      padding: 12px 10px 12px 16px;
      width: auto;
      border-radius: 10px;
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      height: 40px;
    }

    .input-group {
      margin: 0;

      input {
        padding: 12px 10px 12px 38px;
        height: 40px;
      }
    }

    .dropdown-select {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      border-radius: 58px;
      background: #FFFFFF;
      width: auto;
      height: 44px;
      color: #FF6A56;

      .dropdown-select-inner {
        // padding-left: 10px;
        border: 1.6px solid $Grey-04;
        height: 44px;
        // padding-right: 0px !important;
      }

    }
  }

  .dropdown-select .dropdown-select-inner__value-container {
    padding-left: 0px; 
}
  svg.viewLink {
    color: $Blue;
    width: 24px;
    height: 24px;
    stroke-width: 2px;
  }

  .item-image-table {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $Grey-04;

    // img{
    //   width: 40px;
    //   height: 40px;
    // }

    .dropdown-select-inner__flag{
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 9999px;
      border: 1px solid $Grey-03;
    }

    .round-profile-image {
      display: flex;
      align-items: center;
      border-radius: 50%;
      gap: 8px;
      overflow: hidden;
      color: #FFFFFF;
      transition: scale 0.3s ease-in-out;
      width: 40px !important;
      height: 40px !important;
      flex-shrink: 0;
      justify-content: center;
      border: 1px solid $Grey-03;
  }

  .item-image{
    display: flex;
    align-items: center;
    overflow: hidden;
    color: #FFFFFF;
    width: 66px ;
    height: 50px ;
    flex-shrink: 0;
    justify-content: center;

  }
  }

  .item-flag-table {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 54px;

  }


  .category-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $Dark;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    gap: 4px;

    svg{
      width: 25px;
    }
    &.icon-tools {
      svg {
        color: $Green;
      }
    }

    &.icon-resos {
      svg {
        color: $Purple;
      }
    }

    &.icon-ideaz {
      svg {
        color: $Blue;

      }
    }
  }

  .action-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      cursor: pointer;
      height: 26px;
      width: 26px;
      color: $Grey_05;
      stroke-width: 1.4px;
      margin: 2px;
    }

    :hover {
      color: $Orange;
    }
  }

  

  .dashboard-table-header-items {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .input-group {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 !important;
  }

  .addItemDashboardPopup-container .addItemDashboardPopup-col-2 .addItemDashboardPopup-form .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    row-gap: 4px;

    .checkbox .check-box.checked {
      background-color: $Orange;
      border: 2px solid $Orange;
      color: $White !important;
      transition: all 0.3s ease-in-out;
  }
  }
}

.dashboard-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-radius: 10px;
  max-width: 1100px;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid $Grey-03;
  background-color: $Grey-02;

  .items-thead {
    color: $Grey-07;

    tr {
      cursor: pointer;
    }

    th:hover {
      background-color: #EEEFF4;
      color: $Orange;
    }

    th:nth-child(1) {
      min-width: 120px;
    }

    th:nth-child(2) {
      min-width: 80px;
    }

    th:nth-child(3) {
      width: 100%;
    }
    th:nth-child(4) {
      width: 120px;
      min-width: 120px;
    }

    th:nth-child(5) {
      width: 120px;
    }

    th:nth-child(6) {
      width: 72px;
      max-width: 72px !important;
    }

    th:nth-child(7) {
      min-width: 100px;
    }

    th {
      border: 1px solid $Grey_03;
      padding: 8px 10px;
      height: 48px;
      background-color: $Grey-00;

      label {
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        // color: var(--def-dark, $Text_color);
        font-family: Outfit;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 85%;
        cursor: pointer;
        /* 13.6px */
      }

      svg {
        width: 26px;
        height: 26px;
        cursor: pointer;
        // color: $Grey-06;
        stroke-width: 1.3px;

        :hover {
          color: $Orange;
          background: #FF6956;
        }

      }


    }
  }
     .users-thead {
       color: $Grey-07;
  
       tr {
         cursor: pointer;
       }
  
       th:hover {
         background-color: #EEEFF4;
         color: $Orange;
       }
  
       th:nth-child(1) {
        max-width: 44px;
        width: 44px;
       }
  
       th:nth-child(2) {
        max-width: 60px !important;
        width: 60px;
       }
  
       th:nth-child(3) {
         width: 70px;
        width: 70px;
       }
  
       th:nth-child(4) {
         width: 140px;
        max-width: 140px;
       }
  
       th:nth-child(5) {
         width: 120px !important;
        max-width: 120px;
       }
  
       th:nth-child(6) {
        width: 110px;
          max-width: 110px;
       }
  
       th:nth-child(7) {
        width: 88px;
        max-width: 88px;
       }
      th:nth-child(8) {
        width: 88px;
        max-width: 88px;
       }
      th:nth-child(9) {
        width: 100px !important;
        max-width: 100px !important;
       }
      th:nth-child(10) {
        width: 42px;
        max-width: 42px;
       }
  
       th {
         border: 1px solid $Grey_03;
         padding: 8px 10px;
         height: 48px;
         background-color: $Grey-00;
  
         label {
           display: flex;
           justify-content: space-between;
           align-items: center;
           align-self: stretch;
           // color: var(--def-dark, $Text_color);
           font-family: Outfit;
           font-size: 16px;
           font-style: normal;
           font-weight: 400;
           line-height: 85%;
           cursor: pointer;
           /* 13.6px */
         }
  
         svg {
           min-width: 26px;
           height: 26px;
           cursor: pointer;
           // color: $Grey-06;
           stroke-width: 1.3px;
  
           :hover {
             color: $Orange;
             background: #FF6956;
           }
  
         }
  
  
       }
     }

  tbody {
    tr {
      font-family: Outfit;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      // height: 62px;
 
      &:nth-child(even) {
        background-color: $Grey-00;
      }

      &:nth-child(odd) {
        background-color: $White;
      }

      &:hover {
        background-color: $Grey-01;
      }

      td {
        padding: 0px 10px;
        border: 1px dashed $Grey_03;
        line-height: 100%;
        // height: 62px;
        // text-align: right;


        .align-left {
          width: 100%;
          display: flex;
          font-size: 17px;
          line-height: 104%;
          color: $Dark;
        }
        .table-date-time {
          text-align: right;
          width: 100%;
          display: flex;
          font-size: 16px;
          line-height: 104%;
          color: $Grey-07;
        }
        .table-number{
          text-align: right !important;
          font-size: 18px;
        }
        .align-center {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .align-right {
          width: 100%;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

.dashboard-table td {
  height: 50px;
}

.dashboard-table tr:first-child td {
  border-top: none;
}

.dashboard-table tr:last-child td {
  border-bottom: none;
}

.dashboard-table tr td:first-child {
  border-left: none;
}

.dashboard-table tr td:last-child {
  border-right: none;
}

.dashboard-table thead th:last-child {
  border-right: none;
}

.dashboard-table thead th:first-child {
  border-left: none;
}

.dashboard-table thead th:last-child {
  border-right: none;
}

.dashboard-table thead th {
  border-top: none;
}

.dashboard-table-cont {
  border-radius: 10px;
  border: 1px solid var(--gray-grey-03, #DEDEEB);
  overflow: hidden;
  max-width: 1110px;
  min-width: 760px;
  width: 100%;
}

.dashboard-table-header-icon {
  // color: $Grey-06;
  stroke-width: 1.6px;
  width: 28px !important;
  height: 28px !important;
  flex-shrink: 0;
}

.dashboard-delete-button-2 {}

.dashboard-delete-button-1 {
  background-color: #FFF;
  border: 2px solid #FF6956 !important;
  color: #FF6956 !important;

  &:hover {
    border: 0px !important;
    color: #FFF !important;
  }
}

.dashboard-delete-info-1 {
  padding: 0px 15px 0px 15px;
  margin-bottom: 15px;
}

@import "variables";

.infoPopup-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 32px;
  align-items: center;

  .infoPopup {
    display: flex;
    justify-content: space-between;
    width: 380px;
    padding: 12px 8px 12px 16px;
    border-radius: 8px;

    svg {
      height: 24px;
      width: 24px;
    }

    .close {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $Orange;

      &:hover {
        color: $Dark;
      }

      svg {
        cursor: pointer;
        height: 20px;
        width: 20px;
        stroke-width: 2.2px;
      }
    }
  }
}

.cards-termsofuseuxers {
  max-width: 576px !important;
  width: 95% !important;
  padding: 48px;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
    color: $Orange;
    font-family: Outfit;
    font-size: 1.75rem;
    margin-bottom: 32px;

    .card-logo {
      display: flex;
      gap: 6px;

      svg.logo {
        height: 64px;
      }

      .card-logo-text {
        color: $Orange;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    margin-bottom: 32px;

    p {
      color: $Dark;
      font-size: 1rem;
      font-family: Outfit;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    span {
      color: $Orange;
      font-family: Outfit;
      font-size: 1.375rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }

    ol {
      li {
        color: $Dark;
        font-family: Outfit;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  .card-footer {
    width: 100%;

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px 12px 16px;
      border-radius: 10px;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        background: $Dark;
      }
    }
  }
}

.cards-joinuxer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 486px !important;
  width: 95% !important;
  padding: 32px 48px;

  .card-header {
    width: 100%;
    color: $Orange;
    font-family: Outfit;
    font-size: 1.75rem;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .card-body-about {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .card-body-about-message {
        color: $Dark;
        font-family: Outfit;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
        padding: 8px 12px;
      }

      .card-body-about-info {
        color: $Orange;
        font-family: Outfit;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 116%;
        padding: 8px 12px;
      }
    }
  }
}

.cards-requestsent {
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 460px !important;
  width: 95% !important;
  padding: 48px;

  .card-logo {
    display: flex;
    align-items: center !important;
    justify-content: center !important;
    width: 100% !important;

    svg {
      height: 104px;
      width: auto;
    }
  }

  .card-title {
    color: $Orange;
    text-align: center;
    width: 100%;
    padding: 8px 12px;
    text-align: center;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  p {
    color: $Dark;
    padding: 8px 12px;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .close-btn {
    width: 100%;

    button {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      padding: 12px 10px 12px 16px;
      background-color: $Orange;
      border: none;
      border-radius: 10px;
      color: $White;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      width: 100%;
      transition: all .3s ease-in-out;

      svg {
        height: 24px;
        width: 24px;
      }

      &:hover {
        background-color: $Dark;
      }
    }
  }
}

.addItemDashboardPopup-container {
  position: relative;
  max-width: none;
  width: 100%;
  background-color: $White;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 32px;
  border-radius: 20px;

  .addItemDashboardPopup-col-2 {
    display: flex;
    gap: 32px;

    .addItemDashboardPopup-render {
      display: flex;
      padding: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-radius: 16px 0 0 16px;
      background-color: $Grey-02;
    }

    .avatar_box {
      margin: 0px !important;
    }
    .textarea {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 10px 12px;
      gap: 4px;
      align-self: stretch;
      position: relative;
      width: 100%;
      height: 150px;
      border-radius: 16px;
      border: 1.6px solid #CCCEDE;
      background: #FFFFFF;
      overflow: hidden;
  }
    .addItemDashboardPopup-form {
      display: flex;
      flex-direction: column;
      width: auto;
      gap: 8px;
      padding: 20px 24px 32px 0px;

      .form-group {
          display: flex;
          flex-direction: column;
          gap: 8px;
      
          .form-title {
            color: $Orange;
            font-family: Outfit;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 116%;
            padding: 0 0 0 8px;
          }
          .radiobox .radio-box.checked {
            background-color: $Orange;
            border: 2px solid $Orange;
            color: $White !important;
            transition: all 0.3s ease-in-out;
          }
        }
        .more_padding{
          padding: 8px 0;
        }
                .item_photo_hero {
                  // width: 260px;
                  height: 120px;
                  display: flex;
                  align-items: center !important;
                  justify-items: center !important;
                  flex-direction: row ;
                  gap: 20px!important;

                  .no-logo,
                    img {
                      width: 140px !important;
                      height: 100px !important;
                      padding: 0px !important;
                      border-radius: 8px !important;
                      background-image: repeating-linear-gradient(0deg, $Grey-05, $Grey-05 7px, transparent 7px, transparent 17px, $Grey-05 17px), repeating-linear-gradient(90deg, $Grey-05, $Grey-05 7px, transparent 7px, transparent 17px, #333333 17px), repeating-linear-gradient(180deg, $Grey-05, $Grey-05 7px, transparent 7px, transparent 17px, $Grey-05 17px), repeating-linear-gradient(270deg, $Grey-05, $Grey-05 7px, transparent 7px, transparent 17px, $Grey-05 17px);
                      background-size: 2px calc(100% + 17px), calc(100% + 17px) 2px, 2px calc(100% + 17px), calc(100% + 17px) 2px;
                      background-position: 0 0, 0 0, 100% 0, 0 100%;
                      background-repeat: no-repeat;
                      animation: borderAnimation 0.8s infinite linear;
                    }

                    @keyframes borderAnimation {
                      from { background-position: 0 0, -17px 0, 100% -17px, 0 100%; }
                      to { background-position: 0 -17px, 0 0, 100% 0, -17px 100%; }
                      }

                  .no-logo{
                    background-color: $White !important;
                  }

                  .item_photo_icon{
                    min-height: 40px !important;
                    min-width: 40px !important;
                    stroke-width: 1.6px;
                    }
                    
                    .cropped-img {
                      width: 200px;
                      height: 80px;
                      display: flex;
                      align-items: center;
                      justify-items: center;
                      flex-direction: row;
                      gap: 8px;
                    
                      svg {
                        width: 44px;
                        height: 44px;
                        padding: 6px;
                        background-color: $Grey-01;
                        border-radius: 50%;
                        color: $Orange;
                        cursor: pointer;
                    
                        &:hover {
                          background-color: $Orange;
                          border-radius: 50%;
                          color: $White;
                        }
                      }
                }
              }
            // .item_photo_box {
            //   display: flex;
            //   align-items: center;
            //   justify-items: center;
            //   flex-direction: row;
            //   position: relative;
            //   padding: 0;
            //   flex-direction: row;
            //   width: 120px;
            //   height: 88px;
            //   margin: 0px;
            //   border-radius: 0px;
            //   background-color: #BBBDD4;
      
            //   .item_photo {
            //     position: relative;
            //     display: flex;
            //     flex-direction: column;
            //     align-items: center;
            //     justify-items: center;
            //     border-radius: 0px;
            //     background-color: magenta;

            //     img{
            //       background-color: #FF6956;
            //     }
            //     .icon {
            //       width: 40px !important;
            //       stroke-width: 1.6px !important;
            //       background-color: #FF6956 !important;
            //     }
      
            //     .item_photo_icon {
            //       left: 68px;
            //     }
            //     svg {
            //       height: 100%;
            //       width: 100%;
            //       background-color: aquamarine;
            //     }
            //   }
            // }


      .checkbox-group {
        display: flex;
        flex-wrap: wrap;
        gap: 0px;
        row-gap: 8px;

        .checkbox-element {
          padding-left: 8px;
        }
      }

      textarea {
        display: flex;
        height: 104px !important;
        // padding: 12px;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
      }
    }
  }
}

.basic-popup-card {
  display: flex;
  width: 400px;
  padding: 32px 40px 40px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: $White;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.07), 0px 0px 1px 0px rgba(0, 0, 0, 0.07), 0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 2px 0px rgba(0, 0, 0, 0.01), 0px 6px 2px 0px rgba(0, 0, 0, 0.00);

  .card-title {
    display: flex;
    padding: 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: $Orange;
    text-align: center;
    font-family: Outfit;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
  }

  p {
    display: flex;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: $Dark;
    text-align: center;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
  }

  .btn-group {
    svg {
      width: 24px;
      height: 24px;
    }
  }
}

.report-popup {
  display: flex;
  width: 400px;
  padding: 32px 40px 40px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: $White;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.07), 0px 0px 1px 0px rgba(0, 0, 0, 0.07), 0px 1px 1px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 2px 0px rgba(0, 0, 0, 0.01), 0px 6px 2px 0px rgba(0, 0, 0, 0.00);

  .card-title {
    display: flex;
    padding: 8px 12px;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    color: $Orange;
    font-family: Outfit;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    width: 100%;
  }

  p {
    display: flex;
    padding: 0px 8px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: $Dark;
  }

  .radio-form {
    width: 100%;

    .checkbox-radio.checked {
      background-color: $Orange;
      border-color: $Orange;
    }
  }

  .btn-group {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  .switch_box {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $Dark;
    font-family: $Outfit_Font;
    font-weight: 400;
    font-size: 0.9375rem;
    padding: 12px 12px 12px 12px;
    display: flex;
    align-items: center;
    line-height: 110%;
    gap: 8px;

    .checkbox-group {
      margin-right: 8px;
      width: $Width_Checkbox_Large;
      height: $Height_Checkbox_Large;
      flex-shrink: 0;
    }
  }
}


// ----------------------------------------------------------------

@media screen and (max-width: 1024px) {

  #dashboard-items {
    .dashboard-filter-items {
      span {
        display: none;
      }

      .primary {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 8px;
        width: 44px;

        &:hover {
          background-color: $Dark;
        }
      }

      .primary-green {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 8px;
        width: 44px;
        background-color: $Green;

        &:hover {
          background-color: $Dark;
        }
      }

      .secondary {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        width: 44px;
        border: 2px solid #FF6A56;

        &:hover {
          background-color: $Orange;
          color: $White;
        }
      }

    }
  }

  .top-menu-bar{
    .top-title-box {
      flex-direction: column !important;
      align-items: normal !important;
      justify-items: left !important;
      font-size: 24px !important;
      align-content: end;
      gap: 0px !important; 
      line-height: 90% !important;

      .state-body {
        color: var(--def-dark, $Text_color);
        text-align: left;
      }
    }
    }
}

@media screen and (max-width: 960px) {

  .layout-dashboard .left-menu-bar {
    min-width: 88px;
  }

  .layout-dashboard .left-menu-bar svg.logo {
    width: 72px !important;
    height: 72px !important;
  }

  .layout-dashboard .top-menu-bar .top-bar-right-state .statistique-icon svg {
    display: none;
  }

  #dashboard-items .dashboard-filter-items .dashboard-search {
    // width: 200px;
    height: 44px;
  }

}

.app {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  /* background-color:cyan; */
  padding: 32px;
  height: 100%;
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-header {
  cursor: pointer;
  padding: 0 6px 0 16px;
  border: 1.6px solid $Grey-04;
  display: flex;
  align-items: center;
  border-radius: 32px;
  height: 44px;
  gap: 8px;
  justify-content: space-between;
  width: 180px;
  color: $Grey-06;
  font-family: Outfit;

}

.icon {
  // width: 40px !important;
  stroke-width: 1.6px !important;
}

.options-container {
  display: flex;
  gap: 1px;
  flex-direction: column;
  position: absolute;
  top: 110%;
  left: 0;
  width: 100%;
  border: 1.6px solid $Grey-04;
  background-color: #FFF;
  border-radius: 18px;
  overflow: hidden;
  // padding: 4px 0;
  background-color: $Grey-02;
}

.option-item {
  padding: 8px 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  // border-top: 1px solid $Grey-03;
  background-color: $White;
}

.option-item:hover {
  background-color: #eaeaf3a6;
}

.dashboard-edit-popup{
  width: auto !important;
    height: fit-content;
    padding: 0px !important;
}
.dashboard-edit-popup-cont{
  align-items: stretch;
}
.dashboard-edit-subcont-1 {
  display: flex;
  align-items: center;
  gap: 40px;
  // padding-right: 56px;
}
.dashboard-halfinput-cont{
  display: flex;
  gap: 8px;
  flex-direction: column;
}
.dashboard-edit-buttons-cont{
  display: flex;
  gap:10px;
  padding-top: 12px;
}
.dashboard-board-edit-button-0{
  width: 30%;
}
.dashboard-board-edit-button-1 {
  width: 70%;
}
.dashboard-edit-check-cont{
  display: flex;
  padding: 8px 0;
}
.dashboard-edit-check{
  width: 50% !important;
}

.dashboard-edit-lang-cont{
  display: flex;
  gap:5px;

.dashboard-edit-lang{
  background-color: $Orange;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;

   &:hover{
    background-color: $Dark;
  }
}

.dashboard-edit-lang-text {
position: relative;
}

.dashboard-edit-lang-1{
    border: 2px solid var(--gray-grey-05, #BBBDD4);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #BBBDD4;
    width: 40px;
    height: 40px;
    position: relative;
    cursor: pointer;

    &:hover{
      border: 2px solid $Dark;
      color: $Dark;
    }

}

}
.uxer-request-button-cont{
  width: 37px;
  height: 37px ;
  display: flex;
  justify-content: center;
  align-self:center;
  background-color: $Orange;
  border-radius: 8px;
  padding: 5px;
  cursor: pointer;
}
.uxer-request-button{
  color: #FFF;
  stroke-width: 1.3px;
}
.uxer-button-cont {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 5px;
  cursor: pointer;
  stroke-width: 1.6px;
  color: $Orange;

  &:hover{
    background-color: $Grey-03;
    border-radius: 8px;
    // color: $White;
  }
}
// .uxer-button {
//   // color: $Orange;
//   stroke-width: 1.4px;
// }
.th-label{
  justify-content: center !important;
  text-align: left;
}
.th-label-text{
  text-align: left;
}
.uxer-request-position{
  color: var(--def-orange, #FF6956);
    font-family: Outfit;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    /* 16px */
}
.uxer-request-card-cont{
  display: flex;
  width: 100%;
  background-color: $Grey-01;
  flex-direction: column;
  align-items: center;
  // padding: 30px;
  // gap: 5px;
}
.user_card_view_popup_box{
max-width: 460px;
}
.user_card_view_email_box{
  display: flex;
  background-color: $White;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  padding: 6px 20px 6px 12px;
  border-radius: 32px;
  color: $Text_color;
  font-family: Outfit;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;

  svg{
    width: 22px;
    stroke-width: 1.6px;
    color: $Grey-05;
  }
}
.user_card_view_clicks_box{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: $Text_color;
  font-family: Outfit;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;

  .clicks_box{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1px;
    // padding: 0 0 12px;
    }
  svg{
    width: 32px;
    stroke-width: 1.6px;
    color: $Orange;
  }

}
.uxer-request-tag{
  color: var(--Dark, $Text_color);
  padding: 6px 8px;
  background-color: #FFFFFF;
  border-radius: 50px;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}
.uxer-request-button-cont-2{
  width: 100%;
  padding: 20px 40px ;
  background-color: #FFFFFF;
}
.uxer-request-button-cont-3 {
  width: 100%;
  padding: 20px 30px;
  background-color: #FFFFFF;
  display: flex;
  gap:7px
}
.uxer-request-tag-cont{
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  justify-content: center ;
  gap: 5px;
}
.uxer-request-window{
  padding: 0px !important;
  overflow: hidden;
  gap: 0px !important;
    
  .close-btn {
    display: block !important;
    position: absolute !important;
    top: 10px !important;
    right: 10px !important;
    color: $Grey-04 !important;

    &:hover{
      color: $Orange  !important;;
    }
}
}
.dashboard-uxer-username{
  &:hover{
    color: $Orange !important;
  }
}