@tailwind base;
@tailwind components;
@tailwind utilities;

.item-card {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 268px;
  // height: 360px;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: $White;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 2px 0px rgba(0, 0, 0, 0.01), 0px 6px 2px 0px rgba(0, 0, 0, 0.00);
  transition: transform 0.2s ease;

  .item-card-like {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 42px;
    width: 42px;
    padding: 13px 0 0 13px;
    transition: transform .13s ease;

    &.item-not-liked {
      color: $Grey-02;

      &:hover {
        transform: scale(1.15);
      }
    }

    &.item-liked {
      color: $Orange;

      &:hover {
        transform: scale(1.15);
      }

    }
  }

  .item-card-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    height: 120px;

    .item-card-title {
      color: $Text_color;
      text-align: center;
      font-family: Outfit;
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }

    .item-card-subtitle {
      color: $Text_color;
      text-align: center;
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: .5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;


      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-size: 14.2px;
        line-height: 140%;
        font-weight: 500;
        letter-spacing: .4px;
        -webkit-line-clamp: 4;
      }

    }
  }

  &:hover {
    box-shadow: 0 8px 14px 0 rgba(216, 221, 230, 0.5) !important;
    transform: translateY(-4px);
    /* Move the card 7px upwards on hover */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    .item-card-title {
      color: var(--primary-color);
    }
  }

}

.item-card-apps {
  position: relative;
  display: flex;
  width: 268px;
  // height: 360px;
  padding: 24px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 16px;
  background: $White;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05), 0px 0px 1px 0px rgba(0, 0, 0, 0.05), 0px 1px 1px 0px rgba(0, 0, 0, 0.05), 0px 2px 2px 0px rgba(0, 0, 0, 0.04), 0px 4px 2px 0px rgba(0, 0, 0, 0.01), 0px 6px 2px 0px rgba(0, 0, 0, 0.00);
  transition: transform 0.2s ease;

  .item-card-like {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 42px;
    width: 42px;
    padding: 13px 0 0 13px;
    transition: transform .13s ease;

    &.item-not-liked {
      color: $Grey-02;

      &:hover {
        transform: scale(1.15);
      }
    }

    &.item-liked {
      color: $Orange;

      &:hover {
        transform: scale(1.15);
      }

    }
  }

  .item-card-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    height: 120px;

    .item-card-title {
      color: $Text_color;
      text-align: center;
      font-family: Outfit;
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
    }

    .item-card-subtitle {
      color: $Text_color;
      text-align: center;
      font-family: Outfit;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: .5px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      line-clamp: 4;
      -webkit-box-orient: vertical;


      &[data-lang=jp] {
        font-family: 'M PLUS 1p', sans-serif;
        font-size: 14.2px;
        line-height: 140%;
        font-weight: 500;
        letter-spacing: .4px;
        -webkit-line-clamp: 4;
      }

    }
  }



}

.no-designer-role {
  color: $Grey-05;
  font-family: Outfit;
  font-size: 17px;
  line-height: 100%;
}

.item-resos-ideaz {
  // height: 334px;
}


.login-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  width: 100%;

  .login-card-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
  }

  .link-to {
    cursor: pointer;
    display: block;
    padding: 0px 16px;
    width: 100%;
    color: $Orange;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;

    /* 19.2px */
    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
    }

    &:hover {
      color: $Text_color;
    }

  }

  .new-account {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: center;
    width: 100%;
    padding-top: 12px;
  }

}

.forgot-password-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  width: 100%;

  p {
    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin: 0 16px 6px 16px;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
    }
  }

}

.back-link {
  display: block;
  padding: 16px 13px 0;
  width: 100%;
  font-family: Outfit;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: $Text_color;

  &[data-lang=jp] {
    font-family: 'M PLUS 1p', sans-serif;
    font-weight: 500;
    line-height: 130%;
  }

  .link-to {
    cursor: pointer;
    color: $Orange;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */

    &:hover {
      color: $Text_color;
    }
  }
}

.check-email-card {
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;

  .check-email-instruction {
    padding: 0px 16px 8px 16px;
    width: 100%;
    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    /* 17.6px */

    .check-email-email {
      display: block;
      color: $Orange;
    }
  }
}

.success-uxer-card {
  display: flex;
  flex-direction: column;
  gap: 16px;

  p {
    // display: flex;
    padding: 0px 16px 24px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: $Text_color;
    text-align: center;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 116%;

    /* 18.56px */
    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 16.5px;
      line-height: 140%;

      .dynamic_text {
        font-family: Outfit;
        font-size: 18px !important;
        font-weight: 300;


      }

    }

  }

  Button {
    background-color: var(--primary-color);
  }

  .dynamic_text {
    display: contents;
    color: $Orange;
    margin: 0 3px;
    background-color: #BBBDD4;
    width: 100px;

  }
}

.check-email-card.no-gap {
  display: flex;
  gap: 0;
}


.new-password-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;

  p {
    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin: 0 16px 6px 16px;
  }

  .new-password-form {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    width: 100%;
  }

  .new-password-notice {
    display: flex;
    padding: 8px 16px;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    align-self: stretch;

    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
  }
}

.reset-password-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;

  p {
    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 110%;
    margin: 0 16px 6px 16px;
  }
}

.quit-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    display: flex;
    padding: 0px 16px 16px 16px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    color: var(--dark-2, var(--def-darker, $Text_color));
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    &[data-lang=jp] {
      font-family: 'M PLUS 1p', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;
    }

  }
}

.uxer-card {
  position: relative;
  display: flex;
  width: 288px;
  padding: 20px 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  background: $White;

  .uxer-card-edit-btn {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 12px;
    width: 30px;
    height: 30px;
    color: $Grey-03;
    stroke-width: 1.4px;

    &:hover {
      color: var(--primary-color);
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .uxer-card-contact-btn {
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
    width: 30px;
    height: 30px;
    color: $Grey-03;
    stroke-width: 1.4px;

    &:hover {
      color: var(--primary-color);
      stroke-width: 1.6px;

    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .uxer-profile {
    width: 104px !important;
    height: 104px !important;
    border: 2px solid $Grey_00;
    background-color: $Grey-01;

    &.uxer-profile-is-me {
      // border: 3.33px solid var(--primary-color);
    }
  }

  .uxer-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    align-self: stretch;
    margin-bottom: 8px;

    .uxer-fullname {
      color: $Text_color;
      text-align: center;
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 24px */
    }

    .uxer-fullname-empty {
      color: $Grey-05;
      text-align: center;
      font-family: Outfit;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 24px */
    }

    .uxer-rolexp {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;

      .uxer-role {
        color: var(--primary-color);
        text-align: center;
        font-family: Outfit;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 18.7px */
      }

      .uxer-experience {
        display: flex;
        width: 20px;
        height: 14px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 40px;
        background: var(--primary-color);
        color: $White;
        text-align: center;
        font-family: Outfit;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;

      }
    }

    .uxer-nationality {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      height: 16px;

      img {
        display: flex;
        width: 18px;
        height: 18px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        object-fit: cover;
        flex-shrink: 0;
        // border: 1px solid $Grey-02;
        border-radius: 18px;
      }

      span {
        color: $Grey_06;
        text-align: center;
        font-family: Outfit;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 17 */
      }
    }
  }

  .test-p {
    color: $Text_color;
    text-align: center !important;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    height: auto;
    -webkit-line-clamp: 20;
    line-clamp: 20;
    overflow: hidden;
    -webkit-box-orient: vertical;
  }

  p {
    color: $Text_color;
    text-align: center !important;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 17.6px */
    height: 78px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow-wrap: anywhere;

    &.uxer-intro-arabic {
      font-size: 15.5px !important;
      font-family: "Noto Kufi Arabic";
      line-height: 120%;
      font-weight: 400;
    }

    &.uxer-intro-asian {
      font-size: 15.5px;
      font-family: "M PLUS 1p";
      line-height: 132%;
      font-weight: 500;
    }
  }

  .uxer-subinfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    align-self: stretch;
    padding: 0 10px;

    .uxer-username {
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      cursor: pointer;
      color: $Grey_05;
      text-align: center;
      font-family: Outfit;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 100%;
      padding-top: 4px;

      .small_icon {
        margin-top: 3px;
        width: 18px;
        height: 18px;
        color: $Grey-03;
        stroke-width: 2.2px;
      }

      &:hover {
        color: var(--primary-color);

        .small_icon {
          color: var(--primary-color);
        }

      }
    }

    .uxer-links {
      display: flex;
      align-items: center;
      gap: 7px;
      flex-wrap: wrap;
      justify-content: center;

      a {
        color: $Orange;

        svg {
          width: 31px !important;
          height: 100% !important;
          stroke-width: 1.6px !important;

          &:hover {
            color: $Text_color;
            transform: scale(1.2);
            transition: transform .15s ease;
            stroke-width: 1.4px !important;

          }
        }

      }
    }
  }
}

.uxer_card_view{
  width: 348px;
}
.signup-mockup-card{
.uxer-card, .user_card_view {
  width: 306px;
  .uxer-subinfo{
  .uxer-links {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-wrap: wrap;
    justify-content: center;

    a {

      svg {
        width: 31px !important;
        height: 100% !important;
        stroke-width: 1.6px !important;
      }

    }
  }
}
}
}




input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  /* Optional */
}

.uxer-leftbar-card {
  padding: 40px 10px 16px;




  .uxer-username {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    color: $Grey_05;
    text-align: center;
    font-family: Outfit;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;


    .small_icon {
      margin-top: 5px;
      width: 18px;
      height: 18px;
      color: $Grey-03;
      stroke-width: 2.2px;
    }

    &:hover {
      color: var(--primary-color);

      .small_icon {
        color: var(--primary-color);
      }
    }
  }

  .uxer-links {
    display: flex;
    align-items: center;
    // gap: 4px !important;

    // a {
    //   width: 40px;
    //   height: 40px;
    //   background-color: $Grey-00;
    //   display: flex;
    //   align-items: center;
    //   justify-items: center;
    //   justify-content: center;
    //   border-radius: 24px;
    //   color: var(--primary-color) !important;
    //   padding: 6px;

    //   &:hover {
    //     background-color: var(--primary-color);
    //     color: $White !important;

    //     svg{
    //       color: $White !important;
    //       transform: scale(1) !important;
    //       transition: transform .1s ease !important;
    //     }
    //   }

    // }
  }



  P {
    height: fit-content;
    -webkit-line-clamp: 16;
    text-align: center !important;
  }

  // .uxer-subinfo {

  //   .uxer-links a svg {
  //     width: 36px !important;
  //     height: 36px !important;
  //   }
  // }

}

//  -- - - - - - - - - - - - - BLOG - - - - - - - - - - - - - - 
.blog_cards_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  gap: 16px;
  padding: 32px 0;
}

.blog_card_link {
  .blog_card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    gap: 32px;
    padding: 8px;

    .blog_card_image {
      width: 360px;
      height: 200px;
      overflow: hidden;
      border-radius: 16px;
      background-color: $Grey-00;
      border: 3px solid $Grey-00;
    }

    .blog_card_image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .blog_card_content {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: left;
      gap: 24px;
      width: 60%;
      min-width: 576px;

      .blog_category {
        color: $Orange;
        font-family: Outfit;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      .blog_title {
        color: $Dark;
        font-family: Outfit;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
      }

      .blog_info {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        gap: 16px;
      }

      .blog_author_info {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        gap: 10px;
        color: $Dark;
        font-family: Outfit;
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;

        span {
          color: $Grey-03;
        }
      }

      .blog_author_box {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        gap: 4px;
        font-size: 18px;
      }

      .blog_date {
        color: $Grey-06;
      }
    }

  }

  &:hover {
    background-color: $Grey-00;
    border-radius: 24px;

    // .blog_title{
    //   color: $Orange !important;
    // }

  }

}


//  -- - - - - - - - - - - BLOG THE END- - - - - - - - - - - - 
//  --------------------------- MEDIA QUERY ---------------------------

@media screen and (max-width: 1048px) {
  .blog_card_link {

    .blog_card {
      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 28px;
      width: min-content;

      .blog_card_content {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: left;
        gap: 20px;
        min-width: 360px;
      }

    }

    &:hover {
      background-color: $Grey-00;
      border-radius: 24px;

      .blog_title {
        color: $Orange;
      }
    }
  }


}

.leftbar-message {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  padding: 14px 8px 14px 24px;
  position: relative;
  border-bottom: 1px solid $Grey-02;
  cursor: pointer;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */

  &:hover {
    background-color: $Grey-00;
  }

  .leftbar-message-mute-icon {
    width: 20px;
    height: 20px;
    position: absolute;
    stroke-width: 1.8px;
    color: #FFA300;
    top: 6px;
    left: 6px;

    &:hover {
      color: $Orange;
    }

  }

  .leftbar-message-info {
    display: flex;
    justify-content: space-between;
  }

  .leftbar-message-date {
    color: #ABACCA;
    text-align: right;
    font-family: Outfit;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    height: fit-content;
    position: relative;
    top: 4px
  }

  .leftbar-message-text-cont {
    display: flex;
    justify-content: space-between;

  }

  .leftbar-message-text {
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    color: var(--def-orange, #FF6956);
  }

  .leftbar-message-text-opened {
    width: fit-content;
    font-family: Outfit;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    color: var(--def-orange, $Text_color);
  }

  .leftbar-message-text-back {
    width: 22px;
    height: 22px;
    stroke-width: 3px;
    color: #BBBDD4;
  }
}

.main-message {
  padding: 24px;
  background-color: #fff;
  display: flex;
  gap: 10px;
  border-bottom: 1px solid #e7e7f5;

  .main-message-data-cont {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

  }

  .main-message-avatar-cont {}

  .main-message-info-cont {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .main-message-info-name {
    color: $Text_color;
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
  }

  .main-message-info-date-cont {
    display: flex;
    flex-direction: column;
  }

  .main-message-info-date {
    color: var(--Grey---G06, $Grey-05);
    text-align: right;
    font-family: Outfit;
    font-size: 11.5px;
    line-height: 132%;
    font-style: normal;
    font-weight: 400;
  }

  .main-message-text {
    color: var(--Dark, $Text_color);
    font-family: Outfit;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    white-space: pre-wrap;
    word-wrap: break-word;
    width: 400px;
  }
}

.bg-gray {
  background-color: #F5F6FA;
}

.signup-text {
  text-align: center !important;
  font-family: 'Outfit';
  font-size: 17px;
  font-weight: 400;
  line-height: 128%;
  letter-spacing: 0em;
  color: $Text_color;
}

.signup-mockup-card-cont {
  margin: 24px;
}

.signup-mockup-card {
  padding: 8px;
  position: fixed;
  background-color: $Grey-01;
  border-radius: 16px;
}

.signup-mockup-logo {
  color: $Grey-03;
  width: 60px;
  height: 60px;
}

.signup-mockup-logo-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 104px !important;
  width: 104px !important;
  background-color: $Grey-00;
  border: 2.5px solid $Grey-00;
  border-radius: 500px;

  svg {
    color: $Grey-03;
  }

}

.signup_top_hero {}

.signup_top_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;
}

.uxercard-location-empty-cont {
  display: flex;
  gap: 5px;
  align-items: center;
  height: 16px;
}

.uxercard-location-empty-flag {
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  object-fit: cover;
  flex-shrink: 0;
  border: 1px solid $Grey-01;
  background-color: $Grey-02;
  border-radius: 18px;
}

.uxercard-location-empty-text {
  font-family: 'Outfit';
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  color: $Grey-05 !important;
  height: fit-content !important;

}

.uxer-intro-empty-cont {
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  gap: 7px;
  flex-direction: column;
}

.uxer-intro-empty-line {
  background-color: $Grey-01;
  width: 100%;
  height: 7px;
  border-radius: 100px;

}

.uxer-intro-empty-line-2 {
  background-color: $Grey-01;
  width: 100%;
  height: 7px;
  border-radius: 100px;
  width: 66%;

}

.uxercard-links-empty {
  width: 32px;
  height: 32px;
  background-color: $Grey-01 ;
  border-radius: 100%;

}

.uxercard-links-empty-cont {
  display: flex;
  gap: 5px;

}

//  --------------------------------------------------------------------

//  --------------------------- MEDIA QUERY ---------------------------\
@media screen and (max-width: 1135px) {
  .signup-mockup-card-cont {
    display: none;
  }
}

@media screen and (max-width: 960px) {

  .item-card .item-card-like {

    height: 48px;
    width: 48px;
    padding: 13px 0 0 13px;

  }


  .uxer-card .uxer-subinfo .uxer-links {
    a {
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }

  .uxer-username {
    cursor: pointer;
    padding: 3px 12px 5px 6px;
    background-color: $Grey_00;
    border-radius: 20px;
    color: $Grey-05 !important;
  }

  .popup-card-inner {
    display: flex;
    flex-direction: column;
    background-color: #FFF;
    border-radius: 20px;
    width: 94%;
    padding: 48px 60px;
    display: flex;
    gap: 22px;
    align-items: center;
    position: relative;
  }

  .uxer-card .uxer-subinfo .uxer-links {

    gap: 8px;

  }

  .uxer-leftbar-card .uxer-links a {
    width: 45px;
    height: 45px;
  }

}

.forget-svg {
  width: 100px !important;
}

.forget-done-svg {
  width: 100px !important;
}